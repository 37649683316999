import React, { useState } from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'
import Button from 'components/button'
import loadingImage from 'assets/images/loading.svg'

import {
    addRawId
} from 'state/actions'
import { useHistory } from 'react-router-dom'

export default function AddRawId({ type, data }) {
    const { rawId, registration } = data
    const { product } = registration

    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const dispatch = useDispatch()

    const handleSubmit = () => {
        setLoading(true)

        dispatch(addRawId(rawId, registration.id, ({
            type,
            data
        }) => {
            setLoading(false)

            if (type === 'FAILED') {
                setError(data)
            }else{
                closeModal()

				history.push(`/my-products/${data.id}`)
            }
        }))
    }

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: '',
        })
    }

    return (
        <div
            className={`${type} ${loading && 'loading'}`}
            onClick={(e) => e.stopPropagation()}
        >
            <X className='modal-close' onClick={closeModal} />
            <img src={loadingImage} className={`loader`} />
            <div className='inner'>
                <h3>
                    Add this RAW ID to your {product.product_name}?
                </h3>
                {error ?
                    <div className={`form-error`}>
                        <i className={'fa fa-exclamation-triangle'} />
                        <span>{error}</span>
                    </div>
                    : null}
                <div className={'copy'}>
                    This'll allow free activation for certain RAW-supported features.
                </div>
                <div className='code'>
                    {rawId}
                </div>
                <div className="button-row">
                    <Button
                        label={'Add RAW ID'}
                        inverted
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </div>
    )
}
