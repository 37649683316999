import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { useSelector } from 'react-redux'
import { getUserAccountData } from 'state/selectors'

import moment from 'moment'

export const MainContext = React.createContext()

export const ContextProvider = ({ children }) => {
    const history = useHistory()
    const [isHeaderTransparent, setIsHeaderTransparent] = useState(false)

    const logOut = () => {
        window.localStorage.removeItem('token')
        history.push('/auth')
    }

    const userAccountData = useSelector(getUserAccountData)

    const isMobile = typeof window !== 'undefined' ? window.innerWidth <= 500 : false

    const shouldShowPlayProPromo = (() => {
        return false 
        
        if (!userAccountData) {
            return
        }

        const may15 = moment('2023-05-15')

        const { registrations } = userAccountData

        if (!registrations) {
            return
        }

        const applicableProducts = registrations.filter(({
            product_id
        }) => {
            return [145, 153, 154, 155, 159, 170, 149].includes(product_id)
        })

        if(!applicableProducts.length){
            return null
        }

        const hasApplicableProductRegisteredAfterMay15th = applicableProducts.find(({
            created_at
        }) => {
            return moment(created_at).isAfter(may15)
        })

        if(hasApplicableProductRegisteredAfterMay15th){
            return 'FULL'
        }

        return 'PARTIAL'
    })()

    const hasShownPlayProModal = useRef(false)

    return (
        <MainContext.Provider
            value={{
                isHeaderTransparent,
                setIsHeaderTransparent,
                logOut,
                isMobile,
                shouldShowPlayProPromo,
                hasShownPlayProModal
            }}
        >
            {children}
        </MainContext.Provider>
    )
}