import React from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'
import Button from 'components/button'

import { 
    usePlayProContent
} from 'utils'

export default function Promo({ type  }) {
    const {
        bigImage,
        title,
        body,
        button
    } = usePlayProContent()

    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: '',
        })
    }

    return (
        <div className={type} onClick={(e) => e.stopPropagation()}>
            <X className='modal-close' onClick={closeModal} />
            <div>
                <div 
                    className="image"
                    style={{
                        backgroundImage: `url(${ bigImage })`
                    }}
                />
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <div className={'copy'} dangerouslySetInnerHTML={{ __html: body }} />
                <div className="button-row">
                    <Button
                        label={button.title}
                        inverted
                        onClick={() => {
                            button.onClick()
                            closeModal()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
