import React from 'react'

const SupportNavbarDetails = ({ user }) => {
    const userTypes = [
		{
			value: 'EDITOR_COLORIST',
			label: 'Editor/ Colorist'
		},
		{
			value: 'FILM_DOCUMENTARY',
			label: 'Film/ Documentary'
		},
		{
			value: 'HOBBYIST',
			label: 'Hobbyist'
		},
		{
			value: 'PHOTOGRAPHER',
			label: 'Photographer'
		},
		{
			value: 'STREAMING_AV',
			label: 'Streaming & AV'
		},
		{
			value: 'VIDEO_PROFESSIONAL',
			label: 'Video Professional'
		}
	]

    const userTypeLabel = user.user_type ? userTypes.filter(userType => userType.value === user.user_type) : null
    const preferred_edit_software = user.preferred_edit_software ? user.preferred_edit_software : null

	return (
		<>
            {
                ( user.first_name || user.last_name ) &&
                    <h3 dangerouslySetInnerHTML={{ __html: user.first_name + ' ' + user.last_name }} />
            }
            {
                user.phone &&
                    <>
                        <h5 dangerouslySetInnerHTML={{ __html: 'Phone' }} />
                        <div>
                            <a href={ 'tel:' + user.phone } dangerouslySetInnerHTML={{ __html: user.phone }} />
                        </div>
                    </>
            }
            {
                user.email &&
                <>
                    <h5 dangerouslySetInnerHTML={{ __html: 'Email address' }} />
                    <div>
                        <a href={ 'mailto:' + user.email } dangerouslySetInnerHTML={{ __html: user.email }} />
                    </div>
                </>
            }
            {
                user.country &&
                <>
                    <h5 dangerouslySetInnerHTML={{ __html: 'Location' }} />
                    <div dangerouslySetInnerHTML={{ __html: user.country }} />
                </>
            }
            {
                userTypeLabel &&
                <>
                    <h5 dangerouslySetInnerHTML={{ __html: 'User Type' }} />
                    <div dangerouslySetInnerHTML={{ __html: userTypeLabel[0] && userTypeLabel[0].label }} />
                </>
            }
            {
                preferred_edit_software &&
                <>
                    <h5 dangerouslySetInnerHTML={{ __html: 'Preferred edit software' }} />
                    <div dangerouslySetInnerHTML={{ __html: preferred_edit_software }} />
                </>
            }
        </>
	)
}

export default SupportNavbarDetails