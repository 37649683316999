import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useHistory } from 'react-router'

import FormContainer from 'components/forms/formContainer'
import { useDispatch } from 'react-redux'
import { logIn } from 'state/actions'

export default function Login() {
	const [formValues, setFormValues] = useState({})
	const [isLoading, setLoading] = useState(false)
	const [errors, setErrors] = useState({})
	const dispatch = useDispatch()

	const history = useHistory()

	const loginForm = [
		[
			{
				type: 'email',
				placeholder: 'Email address',
				key: 'email'
			}
		],
		[
			{
				type: 'password',
				placeholder: 'Password',
				key: 'password'
			}
		],
		[
			{
				type: 'link',
				text: 'Forgot your password?',
				key: '/auth/forgot-password'
			}
		],
		[
			{
				type: 'button',
				label: 'LOG IN',
				key: 'login'
			}
		]
	]

	const handleChange = (key, value) => {
		const _formValues = { ...formValues }

		_formValues[key] = value

		setFormValues(_formValues)
	}

	const handleError = (key, value) => {
		setErrors({
			[key]: value
		})
	}

	const handleSubmit = () => {
		const { email, password } = formValues

		if (!email) {
			return handleError(null, `Please enter an email address.`)
		}

		if (!password) {
			return handleError(null, `Please enter your password.`)
		}
	
		setLoading(true)

		dispatch(logIn({
			email,
			password
		}, callback => {
			setLoading(false)

			if (callback.type === 'FAILED') {
				return handleError(
					null,
					`Those login details are incorrect.`
				)
			}

			history.push('/')
		}))
	}

	return (
		<div className='container-narrow '>
			<p className='copy large center'>
				Log in and access all information about your Atomos devices in
				one place.
			</p>
			<div className={'form-wrap'}>
				<FormContainer
					rows={loginForm}
					values={formValues}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					loading={isLoading}
					errors={errors}
					hideLabels
				/>
			</div>
			<p className='copy large top center'>
				Don't have an account?
				<NavLink className='animate-link' to='/auth/register'>
					Register here.
				</NavLink>
			</p>
		</div>
	)
}
