import { doRequest, doWordPressRequest } from 'utils'
import axios from 'axios'

export const logIn = (data, callback) => dispatch => {
	doRequest('post', 'auth/apiLogin', data)
		.then(({ data }) => {
			const { token } = data

			window.localStorage.setItem('token', token)
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

			dispatch({
				type: 'LOG_IN',
				payload: data.user
			})

			callback({
				data,
				type: 'SUCCESS'
			})
		})
		.catch(error => {
			callback({
				data: null,
				type: 'FAILED'
			})
		})
}

export const registerUser = (data, callback = () => { }) => dispatch => {
	const {
		formValues,
		_isSupport
	} = data

	doRequest('post', 'auth/registerUser', formValues)
		.then(({ data }) => {
			const { token, user } = data

			if (_isSupport) {
				dispatch({
					type: 'GET_USER_DATA_BY_ID',
					payload: user
				})

				callback({
					data: data,
					type: 'SUCCESS'
				})

			} else {
				if (user) {
					dispatch({
						type: 'LOG_IN',
						payload: user
					})

					callback({
						data: user,
						type: 'SUCCESS'
					})
				}

				if (token) {
					window.localStorage.setItem('token', token)
					axios.defaults.headers.common['Authorization'] =
						'Bearer ' + token
				}
			}

		})
		.catch(({ response }) => {
			if (response && response.data && response.data.errors) {
				callback({
					data: response.data.errors,
					type: 'FAILED'
				})
			} else {
				callback({
					data: {
						null: [
							'An unexpected error has occurred. Please contact support.'
						]
					},
					type: 'FAILED'
				})
			}
		})
}

export const resetPassword = (data, callback = () => { }) => dispatch => {
	doRequest('post', 'auth/resetPassword', data)
		.then(({ data }) => {
			callback({
				data: null,
				type: 'SUCESS'
			})
		})
		.catch(({ response }) => {
			if (response && response.data && response.data.errors) {
				callback({
					data: response.data.errors,
					type: 'FAILED'
				})
			}
		})
}

export const setUserInterests = (data, callback = () => { }) => dispatch => {
	const {
		formValues,
		_isSupport
	} = data

	doRequest('post', 'auth/setUserInterests', formValues)
		.then(({ data }) => {

			if (_isSupport) {
				dispatch({
					type: 'GET_USER_DATA_BY_ID',
					payload: data
				})
			} else {
				dispatch({
					type: 'LOG_IN',
					payload: data
				})
			}

			callback({
				data: data,
				type: 'SUCCESS'
			})
		})
		.catch(({ response }) => {
			callback({
				data: {
					null: [
						'An unexpected error has occurred. Please contact support.'
					]
				},
				type: 'FAILED'
			})
		})
}

export const getUserByToken = callback => dispatch => {
	doRequest('get', 'auth/userByToken')
		.then(response => {
			dispatch({
				type: 'GET_USER_DATA',
				payload: response.data
			})

			callback({
				data: null,
				type: 'SUCCESS'
			})
		})
		.catch(error => {
			callback({
				data: null,
				type: 'FAILED'
			})
		})
}

export const getAcademyPosts = (data, callback) => dispatch => {
	const formData = new FormData()
	formData.append('action', 'get_ajax_posts')

	doWordPressRequest(formData, response => {
		const { data } = response

		dispatch({
			type: 'GET_ACADEMY_POSTS',
			payload: data
		})
	})
}

export const getAcademyPostsByCat = (slug, callback) => dispatch => {
	const formData = new FormData()
	formData.append('action', 'get_ajax_posts_by_category')
	formData.append('slug', `${slug}`)

	doWordPressRequest(formData, response => {
		const { data } = response

		dispatch({
			type: 'GET_ACADEMY_POSTS_BY_CATEGORY',
			payload: data
		})
	})
}

export const getFirmwareUpdates = (callback) => dispatch => {
	const formData = new FormData()
	formData.append('action', 'get_ajax_firmware_updates')

	doWordPressRequest(formData, response => {
		const { data } = response

		dispatch({
			type: 'GET_FIRMWARE_UPDATES',
			payload: data
		})
		callback({
			data,
			type: 'SUCCESS'
		})
	})
}

export const getCameraBrands = () => dispatch => {
	const formData = new FormData()
	formData.append('action', 'get_camera_brands')

	doWordPressRequest(formData, response => {
		const { data } = response

		dispatch({
			type: 'GET_CAMERA_BRANDS',
			payload: data
		})
	})
}

export const getResourceManuals = (callback) => dispatch => {
	const formData = new FormData()
	formData.append('action', 'get_ajax_product_manuals')

	doWordPressRequest(formData, response => {
		const { data } = response

		dispatch({
			type: 'GET_RESOURCES',
			payload: data
		})
		callback({
			data,
			type: 'SUCCESS'
		})
	})
}

export const getDashboardCtas = () => dispatch => {
	const formData = new FormData()
	formData.append('action', 'get_dashboard_ctas')

	doWordPressRequest(formData, response => {
		const { data } = response

		dispatch({
			type: 'GET_DASHBOARD_CTAS',
			payload: data.ctas
		})
	})
}

export const getDashboardHeroPosts = () => dispatch => {
	const formData = new FormData()
	formData.append('action', 'get_hero_posts')

	doWordPressRequest(formData, response => {
		const { data } = response

		dispatch({
			type: 'GET_DASHBOARD_HERO_POSTS',
			payload: data
		})
	})
}

export const registerProduct = (data, callback = () => { }) => dispatch => {
	const isSupport = data.userId ? true : false

	delete data.user

	doRequest('post', 'auth/registerProduct', data)
		.then(({ data }) => {
			if (isSupport) {
				dispatch({
					type: 'GET_USER_DATA_BY_ID',
					payload: data
				})
			} else {
				dispatch({
					type: `POST_REGISTRATION`,
					payload: data
				})
			}

			callback({
				data,
				type: 'SUCCESS'
			})
		})
		.catch(error => {
			const { response } = error

			if (response) {
				callback({
					data: response.data.errors,
					type: 'FAILED'
				})
			}
		})
}


export const getRegistration = (data, callback = () => { }) => dispatch => {
	doRequest('get', 'auth/getRegistration/' + data.registrationId, data)
		.then(response => {
			dispatch({
				type: 'GET_REGISTRATION',
				payload: response.data
			})

			callback({
				data: response.data,
				type: 'SUCCESS'
			})
		})
		.catch(error => {
			console.log(error)

			callback({
				data: null,
				type: 'FAILED'
			})
		})
}

export const postExtendedWarranty = (data, callback = () => { }) => dispatch => {
	const formData = new FormData()
	formData.append('proof_of_purchase', data.proof_of_purchase)

	doRequest(
		'post',
		'auth/postExtendedWarranty/' + data.registrationId,
		formData
	).then(response => {
		dispatch({
			type: 'POST_EXTENDED_WARRANTY',
			payload: response.data
		})

		callback({
			data: response.data,
			type: 'SUCCESS'
		})
	})
}

export const doPearsonActivation = (data, callback = () => { }) => dispatch => {
	doRequest('post', 'auth/doPearsonActivation', data).then(response => {
		dispatch({
			type: 'DO_PEARSON_ACTIVATION',
			payload: response.data.registration
		})

		callback({
			data: response.data,
			type: 'SUCCESS'
		})
	}).catch(error => {
		console.log(error)
		console.log(error.response)
	})
}

export const activateFreeFeature = (data, callback = () => { }) => dispatch => {
	doRequest('post', 'auth/activateFreeFeature', data).then(response => {
		dispatch({
			type: 'ACTIVATE_FREE_FEATURE',
			payload: response.data
		})

		callback({
			data: response.data,
			type: 'SUCCESS'
		})
	})
}

export const deactivateFeature = (feature, registration, callback = () => { }) => dispatch => {
	const data = {
		feature,
		registration: {
			...registration,
			user: null
		}
	}

	doRequest('post', 'auth/deactivateFeature', data).then(response => {
		dispatch({
			type: 'DEACTIVATE_FEATURE',
			payload: response.data
		})

		callback()
	}).catch(error => {
		console.log(error)
	})
}

export const activateAcs = () => dispatch => {
	doRequest('post', 'auth/activateAcs').then(response => {
		console.log(response)

		dispatch({
			type: 'GET_USER_DATA_BY_ID',
			payload: response.data
		})
	}).catch(error => {
		console.log(error)
	})
}

export const getRegisterFormData = (callback = () => { }) => dispatch => {
	doRequest('get', 'auth/registerFormData').then(response => {
		const { data } = response

		dispatch({
			type: 'GET_REGISTER_PRODUCTS',
			payload: data.products
		})

		callback({
			data: data,
			type: 'SUCCESS'
		})
	})
}

export const getProductFeatures = (data, callback = () => { }) => dispatch => {
	doRequest('get', 'auth/productFeatures', data).then(response => {
		const { data } = response

		dispatch({
			type: 'GET_PRODUCT_FEATURES',
			payload: data
		})

		callback({
			data: data,
			type: 'SUCCESS'
		})
	})
}

export const validateCoupon = code => {
	return doRequest('post', 'auth/validateCoupon', { code })
}

export const purchaseFeature = (data, callback = () => { }) => dispatch => {
	doRequest('post', 'auth/processTransaction', data).then(({ data }) => {
		dispatch({
			type: `GET_USER_DATA`,
			payload: data
		})

		callback({
			data: null,
			type: 'SUCCESS'
		})
	}).catch(error => {
		const message = error?.response?.data?.errors || `Transaction failed. Please try a different card, or contact support.`

		callback({
			data: message,
			type: 'FAILED'
		})
	})
}

export const dismissPreferencesPrompt = data => dispatch => {
	doRequest('post', `auth/dismissPreferencesPrompt/${data.id}`)
		.then(({ data }) => {
			dispatch({
				type: `GET_USER_DATA`,
				payload: data
			})
		})
		.catch(error => {
			console.log(error)
		})
}

export const removePaymentMethod = data => dispatch => {
	doRequest('post', `auth/removePaymentMethod`)
		.then(({ data }) => {
			dispatch({
				type: `GET_USER_DATA`,
				payload: data
			})
		}).catch(error => {
			console.log(error)
		})
}

export const checkConnectPromo = (userId, promoType) => {
	return doRequest('post', 'auth/checkConnectPromo', { userId, promoType })
}

export const connectPromo = (userId, resellerName, resellerCountry, promoType) => {
	return doRequest('post', `auth/connectPromo`, { userId, resellerName, resellerCountry, promoType })
}

export const requestPasswordReset = (data, callback) => dispatch => {
	doRequest('post', 'auth/requestPasswordReset', data).then(({ data }) => {
		callback({
			data: null,
			type: 'SUCCESS'
		})
	}).catch(({ response }) => {
		callback({
			data: response.data.errors,
			type: 'FAILED'
		})
	})
}

export const confirmPasswordReset = (data, callback) => dispatch => {
	doRequest('post', 'auth/confirmPasswordReset', data).then(({ data }) => {
		callback({
			data: null,
			type: 'SUCCESS'
		})
	}).catch(({ response }) => {
		callback({
			data: response.data.errors,
			type: 'FAILED'
		})
	})
}

export const searchCustomers = (data, callback) => dispatch => {
	doRequest('post', 'auth/searchCustomers', data).then(({ data }) => {
		dispatch({
			type: 'NORMALIZE_USERS',
			payload: data
		})

		const userIds = data.map(user => user.id)

		callback({
			data: userIds,
			type: 'SUCCESS'
		})
	}).catch(({ response }) => {
		console.log(response)
	})
}

export const searchProducts = (data, callback) => dispatch => {
	doRequest('post', 'auth/searchProducts', data).then(({ data }) => {
		dispatch({
			type: 'NORMALIZE_PRODUCTS',
			payload: data
		})

		const productIds = data.map(product => product.id)

		callback({
			data: productIds,
			type: 'SUCCESS'
		})
	}).catch(({ response }) => {
		console.log(response)
	})
}

export const getUserById = (data, callback = () => { }) => dispatch => {
	doRequest('get', 'auth/userById?userId=' + data.userId)
		.then(response => {
			dispatch({
				type: 'GET_USER_DATA_BY_ID',
				payload: response.data
			})

			callback({
				data: response.data,
				type: 'SUCCESS'
			})
		})
		.catch(error => {
			callback({
				data: null,
				type: 'FAILED'
			})
		})
}

export const deleteUser = (id, callback = () => { }) => dispatch => {
	doRequest('post', `auth/deleteUser/${id}`)
		.then(({ data }) => {

			dispatch({
				type: 'NORMALIZE_USERS',
				payload: data
			})

			callback({
				data: data,
				type: 'SUCCESS'
			})
		})
		.catch(({ response }) => {
			callback({
				data: {
					null: [
						'An unexpected error has occurred. Please contact support.'
					]
				},
				type: 'FAILED'
			})
		})
}


export const deleteRegistration = (id, callback = () => { }) => dispatch => {
	doRequest('post', `auth/deleteRegistration/${id}`)
		.then(({ data }) => {

			dispatch({
				type: 'NORMALIZE_PRODUCTS',
				payload: data
			})

			callback({
				data: data,
				type: 'SUCCESS'
			})
		}).catch(({ response }) => {
			callback({
				data: {
					null: [
						'An unexpected error has occurred. Please contact support.'
					]
				},
				type: 'FAILED'
			})
		})
}

export const reassignRegistration = (registrationId, userId, callback = () => { }) => dispatch => {
	doRequest('post', `auth/reassignRegistration/${registrationId}`, {
		registrationId,
		userId
	}).then(({ data }) => {
		dispatch({
			type: 'NORMALIZE_PRODUCTS',
			payload: [data]
		})

		callback({
			data,
			type: 'SUCCESS'
		})
	}).catch(({ response }) => {
		callback({
			data: {
				null: [
					'An unexpected error has occurred. Please contact support.'
				]
			},
			type: 'FAILED'
		})
	})
}

export const activatePlayPro = (id, callback = () => { }) => dispatch => {
	doRequest('post', `auth/activatePlayPro/${id}`, {})
		.then(({ data }) => {
			callback({
				data,
				type: 'SUCCESS'
			})
		}).catch(({ response }) => {
			callback({
				data: {
					null: [
						'An unexpected error has occurred. Please contact support.'
					]
				},
				type: 'FAILED'
			})
		})
}

export const addRawId = (rawId, registrationId, callback = () => { }) => dispatch => {
	doRequest('post', `auth/addRawId/${registrationId}`, { raw_id: rawId })
		.then(({ data }) => {
			dispatch({
				type: 'NORMALIZE_PRODUCTS',
				payload: [data]
			})

			callback({
				type: 'SUCCESS',
				data
			})
		}).catch(({ response }) => {
			callback({
				type: 'FAILED',
				data: response.data.error || "Something went wrong. Please contact support."
			})
		})
}

export const swapToOs11 = registration => dispatch => {
	doRequest('post', 'auth/swapToOs11', {
		registration_id: registration.id,
	}).then(({ data }) => {
		dispatch({
			type: 'NORMALIZE_PRODUCTS',
			payload: [data]
		})
	}).catch(error => {
		console.log(error)
	})
}