import React, { useState, useEffect } from 'react'
import { formatDate, supportUserCan } from 'utils'
import { getUserAccountData } from 'state/selectors'

import NoData from 'components/singleRegistration/noData'
import SearchTable from 'components/support/searchTable'

import { useDispatch } from 'react-redux'
import {
	searchCustomers,
    searchProducts,
} from 'state/actions'
import { useSelector } from 'react-redux'

import * as schemas from 'state/schemas'
import { denormalize } from 'normalizr'

const SearchInner = ({
    type
}) => {
	const userAccountData = useSelector(getUserAccountData)

    const canEdit = supportUserCan(userAccountData, 'EDIT_DETAILS')

    const [formValues, setFormValues] = useState({})
    const [resultIds, setResultIds] = useState()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    const results = useSelector(state => {
        let schema = null

        switch(type){
            case 'customers':
                schema = schemas.userSchema
                break
            case 'products':
                schema = schemas.registrationsSchema
                break
        }

        return denormalize(resultIds, [schema], state.main.entities)
    })

	const dispatch = useDispatch()

    const handleChange = (key, value) => {
        const _formValues = { ...formValues }

        _formValues[key] = value

        setFormValues(_formValues)
    }

    const inputs = (() => {
        switch (type) {
            case 'customers':
                return [
                    {
                        label: 'Email address',
                        key: 'email'
                    }, {
                        label: 'First name',
                        key: 'firstName'
                    }, {
                        label: 'Last name',
                        key: 'lastName'
                    }
                ]
            case 'products':
                return [
                    {
                        label: 'Product ID',
                        key: 'productId'
                    }, {
                        label: 'Serial number',
                        key: 'serial'
                    }, {
                        label: 'DID',
                        key: 'did'
                    }, {
                        label: 'AID',
                        key: 'aid'
                    }
                ]
        }
    })()

    const _handleSubmit = async e => {
        e.preventDefault()

        switch (type) {
            case 'customers':
                if ( formValues.email || formValues.firstName || formValues.lastName ) {
                    setLoading(true)
        
                    dispatch(searchCustomers( formValues , callback => {
                        if (callback) {
                            setResultIds(callback.data)
                        }
                        setLoading(false)
                    }))
                } else {
                    setResultIds([])
                }
            case 'products':
                if ( formValues.productId || formValues.serial || formValues.did || formValues.aid ) {

                    let doSearch = true

                    if ( formValues.aid && ( formValues.aid.length > 0 && formValues.aid.length < 4 ) ) {
                        setErrorMessage('Please enter more than 3 characters for the AID number.')
                        doSearch = false
                        setResultIds([])
                    }
                    if ( formValues.did && ( formValues.did.length > 0 && formValues.did.length < 4 ) ) {
                        setErrorMessage('Please enter more than 3 characters for the DID number.')
                        doSearch = false
                        setResultIds([])
                    }
                    if ( formValues.serial && ( formValues.serial.length > 0 && formValues.serial.length < 4 ) ) {
                        setErrorMessage('Please enter more than 3 characters for the Serial number.')
                        doSearch = false
                        setResultIds([])
                    }
                    
                    if ( doSearch ) {
                        setErrorMessage()
                        setLoading(true)

                        dispatch(searchProducts( formValues , callback => {
                            if (callback) {
                                setResultIds(callback.data)
                                
                            }
                            setLoading(false)
                        }))
                    }
                } else {
                    setResultIds([])
                }
        }
    }

    return (
        <div className={`search-inner ${type}`}>
            <form onSubmit={_handleSubmit}>
                {inputs.map(({
                    label,
                    key
                }) => {
                    return (
                        <label key={key}>
                            <span>
                                {label}
                            </span>
                            <div>
                                <i className={'fa fa-search'} />
                                <input
                                    type={'text'}
                                    value={formValues[key] || ''}
                                    onChange={e => handleChange(key, e.target.value)}
                                />
                            </div>
                        </label>
                    )
                })}
                <label>
                    <span>
                        &nbsp;
                    </span>
                    <input className={'button inverted'} type="submit" value="Search" />
                </label>
            </form>
            {
                loading ?
                    <div className="search-loading" dangerouslySetInnerHTML={{ __html: 'Loading...' }} />
                :
                    ( results && results.length > 0 ) ?
                        <SearchTable
                            columns={ type === 'customers' ? ['Name', 'Email address', 'Phone', 'Date Registered', 'Edit details'] : ['Product', 'Registered on', 'Serial number', 'DID', 'AID', 'User name', 'User email', 'Edit registration'] }
                            rows={ results }
                            urlGetter={item => {
                                if(item.user_id){
                                    return `/customers/${ item.user_id }/${ item.id }`
                                }

                                return `/customers/${ item.id }`
                            }}
                            getter={(result, key) => {
                                switch(key) {
                                    case 'Name':
                                        return (result.first_name !== null ? result.first_name : '' ) + ' ' + (result.last_name !== null ? result.last_name : '' )
                                    case 'Email address':
                                        return result.email
                                    case 'Phone':
                                        return result.phone
                                    case 'Product':
                                        return result.product ? result.product.product_name : '(No product)'
                                    case 'Registered on':
                                        return result.product ? formatDate(result.created_at) : ''
                                    case 'Serial number':
                                        return result.serial_number
                                    case 'DID':
                                        return result.did
                                    case 'AID':
                                        return result.aid
                                    case 'Date Registered':
                                        return formatDate(result.created_at)
                                    case 'Edit details':
                                        return canEdit ? result.id : null
                                    case 'Edit registration':
                                        return canEdit ? result.product_id : null
                                    case 'User name':
                                        return (result.user && result.user.first_name && result.user.last_name) ? result.user.first_name+' '+result.user.last_name : '(None)'
                                    case 'User email':
                                        return (result.user && result.user.email) ? result.user.email : '(None)'
                                }
                            }}
                        />
                    :
                        errorMessage ?
                            <div className="search-loading" dangerouslySetInnerHTML={{ __html: errorMessage }} />
                        :
                            ( resultIds && resultIds.length === 0 ) ?
                                <NoData
                                    title={`No results to show.`}
                                    copy={`Hit 'Search' to search for ${ type }.`}
                                />
                            :
                                resultIds &&
                                    <div className="search-loading" dangerouslySetInnerHTML={{ __html: 'Please enter a value to search for.' }} />
            }
        </div>
    )
}

export default SearchInner