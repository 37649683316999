import { schema } from 'normalizr'

export const userSchema = new schema.Entity('users')
export const registrationsSchema = new schema.Entity('registrations')
export const productsSchema = new schema.Entity('products')
export const postsSchema = new schema.Entity('posts', {}, {
	idAttribute: 'ID'
})
export const tabsSchema = new schema.Entity('tabs')
export const categoriesSchema = new schema.Entity('categories', {}, {
	idAttribute: 'term_id'
})

export const featureInstanceSchema = new schema.Entity('feature_instances')
export const featureSchema = new schema.Entity('features')
export const warrantySchema = new schema.Entity('upload')
export const roleSchema = new schema.Entity('roles')

userSchema.define({
	registrations: [registrationsSchema],
	roles: [roleSchema]
})

registrationsSchema.define({
	product: productsSchema,
	feature_instances: [featureInstanceSchema],
	upload: warrantySchema,
	user: userSchema
})

postsSchema.define({
	categories: [categoriesSchema]
})

featureInstanceSchema.define({
	feature: featureSchema
})

productsSchema.define({
	features: [featureSchema]
})

categoriesSchema.define({
	posts: [postsSchema]
})
