import React from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'

import Button from 'components/button'

export default function TextModal({ type, data }) {
	const { title, subtitle, copy, button, buttonLow, narrow } = data

	const dispatch = useDispatch()

	const closeModal = () => {
		dispatch({
			type: 'OPEN_MODAL',
			payload: ''
		})
	}

	return (
		<div
			className={`${type} ${narrow ? 'narrow' : ''}`}
			onClick={e => e.stopPropagation()}
		>
			<X className='modal-close' onClick={closeModal} />
			<div>
				{title && (
					<h3
						dangerouslySetInnerHTML={{ __html: title }}
						// style={{ marginBottom: 0 }}
					/>
				)}
				{subtitle && (
					<span className={'subtitle'} dangerouslySetInnerHTML={{ __html: subtitle }} />
				)}
				<div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
				<div className={'buttons'}>
					{buttonLow && (
						<Button
							onClick={
								buttonLow.onClick
									? buttonLow.onClick
									: closeModal
							}
							url={buttonLow.onClick ? '' : buttonLow.url}
							label={buttonLow.label}
							inverted={!button.isInverted}
						/>
					)}
					{button && (
						<Button
							onClick={
								button.onClick ? button.onClick : closeModal
							}
							url={button.onClick ? '' : button.url}
							label={button.label}
							inverted={!!button.isInverted}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
