import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { isSupport, supportUserCan } from 'utils'
import { getUserAccountData } from 'state/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { getUserById } from 'state/actions'
import { useRouteMatch } from 'react-router'

import SupportNavbar from 'components/support/supportNavbar'

import SingleRegistration from 'components/singleRegistration'
import MyProducts from 'components/myProducts'

const SingleUser = () => {
    const [loading, setLoading] = useState(false)

    const match = useRouteMatch()

    const dispatch = useDispatch()

    const loggedInUser = useSelector(getUserAccountData)
    const _isSupport = loggedInUser && isSupport(loggedInUser)
    const canEdit = supportUserCan(loggedInUser, 'EDIT_DETAILS')
    const canActivate = supportUserCan(loggedInUser, 'ACTIVATE_FEATURES')

    const { userId } = useParams()
    const { registrationId } = useParams()

    useEffect(() => {
        if (_isSupport) {
            dispatch(getUserById({ userId }))
        }

        setLoading(true)

        setTimeout(() => {
            setLoading(false)
        }, 1000)

    }, [])

    const userAccountData = useSelector(state => getUserAccountData(state, userId))

    return (
        <div className={loading ? "singleUser loading" : "singleUser"}>
            <SupportNavbar user={loggedInUser} />
            { registrationId ?
                <SingleRegistration
                    isSupport={true}
                    canEdit={canEdit}
                    canActivate={canActivate}
                    _registrationId={registrationId}
                    user={userAccountData}
                    match={match}
                />
                :
                <MyProducts
                    isSupport={true}
                    userData={userAccountData}
                />
            }
        </div>
    )
}

export default SingleUser