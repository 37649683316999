import React from 'react'
import { Link } from 'react-router-dom'
import {
	ChevronLeft,
	ArrowRight,
	Edit2,
	Check,
	Download,
	PlusCircle
} from 'react-feather'

export default function Button({
	label,
	subLabel,
	url,
	naked,
	back,
	download,
	edit,
	register,
	inverted,
	outline,
	full,
	success,
	onClick: _onClick,
	compact,
	disabled
}) {
	const className = (() => {
		let _className = 'button'

		if (inverted) {
			_className += ' inverted'
		}

		if (outline) {
			_className += ' outline'
		}

		if (naked) {
			_className += ' naked'
		}

		if (back) {
			_className += ' back'
		}

		if (edit) {
			_className += ' edit'
		}

		if (register) {
			_className += ' register'
		}

		if (full) {
			_className += ' full-width'
		}

		if (success) {
			_className += ' success'
		}

		if (download) {
			_className += ' download'
		}

		if (compact) {
			_className += ' compact'
		}

		if (disabled) {
			_className += ' disabled'
		}

		return _className
	})()

	const onClick = _onClick ? () => {
		if (!disabled && _onClick) {
			_onClick()
		}
	} : undefined

	const iconSize = compact ? 10 : 14

	const buttonProps = {
		className
	}

	const inner = (
		<>
			{naked && back && <ChevronLeft size={iconSize} />}
			{success && <Check size={iconSize} strokeWidth={4} />}
			{download && <Download size={iconSize} />}
			{edit && <Edit2 size={iconSize} />}
			{register && <PlusCircle size={iconSize} />}
			<div>
				<span dangerouslySetInnerHTML={{ __html: label}} />
				{subLabel &&
					<span className={'subtitle'}>
						{subLabel}
					</span>
				}
			</div>
			{naked && !back && !download && <ArrowRight size={iconSize} />}
		</>
	)

	if (url && (~url.indexOf('http') || ~url.indexOf('https'))) {
		return (
			<a {...buttonProps} href={url} target={'_blank'}>
				{inner}
			</a>
		)
	} else if (url) {
		return (
			<Link {...buttonProps} to={url}>
				{inner}
			</Link>
		)
	} else {
		return (
			<button onClick={onClick} {...buttonProps}>
				{inner}
			</button>
		)
	}
}
