import React from 'react'

import FlagIcon from 'assets/images/flag.svg'

export default function NoData({
    title,
    copy
}) {
    return (
        <div className='no-data'>
            <section className='icon'>
                <img src={FlagIcon} alt='flag icon'/>
            </section>
            <section className='content'>
                <div className='h3' dangerouslySetInnerHTML={{ __html: title }}/>
                <div className='copy large' dangerouslySetInnerHTML={{ __html: copy }}/>
            </section>
        </div>
    )
}
