import React from 'react'

import logo from 'assets/images/atomos-academy-logo.png'
import bg from 'assets/images/academy-hero-bg.jpg'

export default function AcademyHero() {
	return (
		<div className='academy-hero' style={{ backgroundImage: `url(${bg})` }}>
			<div className='academy-hero__content'>
				<img src={logo} alt='atomos academy logo' />
				<h3>Learn, Inspire, Create.</h3>
				<p className='copy large'>
					Dig in and explore new content from Creators, tips from Pros
					and the latest innovations from Atomos. Find out how
					professionals are using Atomos equipment to unlock the full
					potential of their cameras and kit.
				</p>
			</div>
		</div>
	)
}
