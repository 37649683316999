import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'
import { activateFreeFeature } from 'state/actions'
import loadingImage from 'assets/images/loading.svg'

import Button from 'components/button'

export default function TextModal({
    type,
    data,
}) {
    const [loading, setLoading] = useState(false)

    const {
        title,
        copy,
        button,
        featureId,
        registration,
        subSection
    } = data

    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: ''
        })
    }

    useEffect(() => {
        setLoading(true)

        dispatch(activateFreeFeature({ featureId, registrationId: registration.id }, () => {
            setLoading(false)

        }))
    }, [])

    return (
        <div className={loading ? type + " loading" : type} onClick={e => e.stopPropagation()}>
            <img src={loadingImage} className={`loader`} />
            <X className="modal-close" onClick={closeModal} />
            {loading ?
                <div dangerouslySetInnerHTML={{ __html: 'Loading...' }} />
                :
                <div>
                    {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
                    <div dangerouslySetInnerHTML={{ __html: copy }} />
                    {button &&
                        <Button
                            onClick={button.onClick ? button.onClick : ''}
                            url={button.onClick ? '' : button.url}
                            label={button.label}
                            inverted={!!button.isInverted}
                        />
                    }
                    {subSection ?
                        <div className={'sub-section'}>
                            <h3 dangerouslySetInnerHTML={{ __html: subSection.title }} />
                            <div dangerouslySetInnerHTML={{ __html: subSection.copy }} />
                            <Button
                                url={subSection.button.url}
                                label={subSection.button.label}
                                inverted={!!subSection.button.isInverted}
                            />
                        </div>
                        : null}
                </div>
            }
        </div>
    )
}
