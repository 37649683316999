import React from 'react'

import logo from 'assets/images/myatomos-logo.png'

const PageLoader = ({ 
	visible,
	noLogo
}) => {
	return (
		<dialog className={`page-loader ${visible ? 'visible' : ''} ${ noLogo ? 'no-logo' : ''}`}>
			<div className='loading-waves' />
			{ !noLogo ?
				<img src={logo} />
			: null }
		</dialog>
	)
}

export default PageLoader