import React, { useState } from 'react'
import loadingImage from 'assets/images/loading.svg'

import Button from 'components/button'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

import { Link } from 'react-router-dom'

export default function FormContainer({
	values,
	rows = [],
	handleChange,
	handleSubmit,
	errors = {},
	response,
	heading,
	copy,
	handleClick,
	loading,
	extraClass,
	hideLabels,
	hideLoader,
	skipForNow,
	_skipForNow,
	alreadyRegistered,
}) {
	const [focusedTooltipRow, setFocusedTooltipRow] = useState(null)
	const [activeFormRow, setActiveFormRow] = useState()

	return (
		<form
			className={`form-container ${loading ? 'loading' : ''}`}
			onSubmit={e => {
				e.preventDefault()

				if (handleSubmit) {
					handleSubmit()
				}
			}}
		>
			{skipForNow &&
				<div className="skipForNow animate-link" onClick={_skipForNow}>
					Skip for now <i className={'fa fa-angle-right'} />
				</div>
			}
			{!hideLoader ? (
				<img src={loadingImage} className={`loading`} />
			) : null}
			<div className={'loader-block'}>
				{heading && <h3>{heading}</h3>}
				{copy && <p className='copy large'>{copy}</p>}
				{errors[null] && (
					<div className={'form-error'}>
						<i className={'fa fa-exclamation-triangle'} />
						<span>{errors[null]}</span>
					</div>
				)}
				{response &&
					<div className={'form-error'}>
						<i className={'fa fa-check'} />
						<span>{response}</span>
					</div>
				}
				{rows.map((row, index) => {
					const nonHiddenRows = row.filter(column => !column.hidden)

					if (!nonHiddenRows.length) {
						return null
					}

					return (
						<div
							key={index}
							className={activeFormRow === index ? `form-row form-row-active columns-${row.length}` : `form-row columns-${row.length}`}
							onClick={() => setActiveFormRow(index)}
						>
							{nonHiddenRows.map((column, columnIndex) => {
								const {
									type,
									placeholder,
									key,
									label,
									text,
									options,
									tiles,
									readOnly,
									tooltip
								} = column

								let component = null

								const value = values[key] || ''

								const columnKey = `${index}.${columnIndex}`
								const isFocused = focusedTooltipRow === columnKey

								switch (type) {
									case 'password':
									case 'email':
									case 'text':
										component = (
											<>
												{!hideLabels && (
													<>
														<label
															htmlFor={key}
															onMouseEnter={() => setFocusedTooltipRow(columnKey)}
															onMouseLeave={() => setTimeout(() => setFocusedTooltipRow(null), 100)}
															style={{
																zIndex: isFocused ? 2 : 1,
																opacity: isFocused ? 1 : 0.7
															}}
														>
															{placeholder}
														</label>
														{tooltip &&
															<span className={'tooltip'}>
																<i className={'fa fa-info-circle'} />
																{tooltip.label}
																<div className={'tooltip-inner'} dangerouslySetInnerHTML={{ __html: tooltip.copy }} />
															</span>
														}
													</>
												)}
												<input
													className='general-input'
													{...column}
													placeholder={placeholder}
													onChange={e =>
														handleChange(
															key,
															e.target.value
														)
													}
													id={key}
													value={value}
													noValidate
													readOnly={readOnly}
												/>
											</>
										)
										break
									case 'button':
										component = (
											<Button
												label={label}
												full
												onClick={e => {
													if (handleClick) {
														e.preventDefault()
														e.stopPropagation()
														handleClick()
													}
												}}
											/>
										)
										break
									case 'link':
										component = (
											<div className={'link'}>
												{~key.indexOf('http') ?
													<a href={key} target="_blank">
														{text}
													</a>
													:
													<Link to={key}>
														{text}
													</Link>
												}
											</div>
										)
										break
									case 'checkbox':
										component = (
											<label className={`checkbox ${!!value ? 'checked' : ''}`}>
												<div className={'checkbox-inner'}>
													<input
														type={'checkbox'}
														checked={!!value}
														onChange={() => handleChange(key, !value)}
													/>
													<span>
														<i className={'fa fa-check'} />
													</span>
												</div>
												<span dangerouslySetInnerHTML={{ __html: text }} />
											</label>
										)
										break
									case 'select':
										component = (
											<>
												<label>{label}</label>
												<Select
													className='react-select-container'
													classNamePrefix='react-select'
													key={index}
													options={options}
													placeholder={placeholder}
													onClick={() => setActiveFormRow()}
													isOptionDisabled={option => option.disabled}
													onChange={option =>
														handleChange(
															key,
															option.value
														)
													}
													value={
														value
															? options.find(
																option =>
																	option.value ===
																	value
															)
															: null
													}
													isDisabled={
														readOnly ? true : false
													}
												/>
											</>
										)
										break
									case 'tiles':
										component = (
											<>
												<label>{label}</label>
												<div className='tile-cont'>
													{tiles.map(tile => {
														return (
															<a
																onClick={e =>
																	handleChange(
																		key,
																		tile.value
																	)
																}
																className={`tile ${value ===
																	tile.value
																	? 'active'
																	: ''
																	}`}
																key={tile.value}
															>
																{tile.label}
															</a>
														)
													})}
												</div>
											</>
										)
										break
									case 'date':
										component = (
											<>
												{!hideLabels && (
													<label htmlFor={key}>
														{label}
													</label>
												)}
												<DatePicker
													onChange={newValue => {
														const formattedValue = moment(
															newValue
														).format('YYYY-MM-DD')
														handleChange(
															key,
															formattedValue
														)
													}}
													selected={
														value &&
															value !== '0000-00-00'
															? moment(
																value
															).toDate()
															: null
													}
													dateFormat={'dd-MM-yyyy'}
													readOnly={readOnly}
													popperPlacement="auto"
												/>
											</>
										)
										break
								}

								return (
									<div
										key={columnIndex}
										className={`form-column ${extraClass ? extraClass : ''
											} column-${type}`}
									>
										{component}
									</div>
								)
							})}
						</div>
					)
				})}
			</div>
		</form>
	)
}
