import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUserAccountData } from 'state/selectors'
import { useMainContext, useModal, supportUserCan } from 'utils'
import usePlayProModal from 'utils/usePlayProModal'
import { PlusCircle } from 'react-feather'

import ProductTile from 'components/productTile'
import Button from 'components/button'

export default function MyProducts({ userData, isSupport }) {
	const { 
		setIsHeaderTransparent,
	} = useMainContext()

	const userAccountData = useSelector(getUserAccountData)
	const registrations = userData ? userData.registrations : userAccountData.registrations

	usePlayProModal(userAccountData)

    const canEdit = !isSupport || supportUserCan(userAccountData, 'EDIT_DETAILS')

	const doModal = useModal()

	const openRegisterModal = () => {
		doModal({
			type: 'REGISTER_PRODUCTS',
			data: {
				title: ( isSupport && userData ) ? 'Register a device for ' + userData.first_name + ' ' + userData.last_name : 'Register a device',
				copy: `Edit your details here.`,
				user: userData,
				isSupport,
			}
		})
	}

	useEffect(() => {
		setIsHeaderTransparent(false)
	}, [])

	return (
		<div className='myProducts'>
			{ isSupport &&
				<Button
					url={'/support/customers/'}
					label='Back to all'
					naked
					back
				/>
			}
			<h2 dangerouslySetInnerHTML={{ __html: ( isSupport && userData ) ? userData.first_name + ' ' + userData.last_name + "'s Registered Devices" : 'My Atomos Devices' }} />
			<ul>
				{registrations &&
					registrations.map((registration, index) => {
						if(!registration.product){
							return null 
						}
						
						return (
							<li key={index}>
								<ProductTile registration={registration} type='products-page' isSupport={ isSupport } />
							</li>
						)
					})}
				{ canEdit &&
					<li className='myProductsSlider-register-cont'>
						<div className='myProductsSlider-register'>
							<a onClick={openRegisterModal}>
								<PlusCircle size={80} strokeWidth={0.5} />
								<h5 dangerouslySetInnerHTML={{ __html: 'Register Device' }} />
							</a>
						</div>
					</li>
				}
			</ul>
		</div>
	)
}
