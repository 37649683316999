import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getModalData } from 'state/selectors'

import ActivateFeature from './modalTypes/activateFeature'
import AddRawId from './modalTypes/addRawId'
import Text from './modalTypes/text'
import EditProfile from './modalTypes/editProfile'
import DeleteUser from './modalTypes/deleteUser'
import DeleteRegistration from './modalTypes/deleteRegistration'
import DownloadToken from './modalTypes/downloadToken'
import RegisterProduct from './modalTypes/registerProduct'
import ResetPassword from './modalTypes/resetPassword'
import GetPin from './modalTypes/getPin'
import Reassign from './modalTypes/reassign'
import Confirm from './modalTypes/confirm'
import Promo from './modalTypes/promo'
import ConnectPromo from './modalTypes/connectPromo'

const Modal = () => {
	const dispatch = useDispatch()

	const modalData = useSelector(getModalData)

	const closeModal = () => {
		dispatch({
			type: 'OPEN_MODAL',
			payload: '',
		})
	}

	const modalVisible = modalData && modalData.type ? true : false

	const modalProps = {
		closeModal,
		...modalData
	}

	let Component = null

	if (modalData) {
		switch (modalData.type) {
			case 'ACTIVATE_FEATURE':
				Component = ActivateFeature
				break
			case 'TEXT':
				Component = Text
				break
			case 'EDIT_PROFILE':
				Component = EditProfile
				break
			case 'DELETE_USER':
				Component = DeleteUser
				break
			case 'DELETE_REGISTRATION':
				Component = DeleteRegistration
				break
			case 'DOWNLOAD_TOKEN':
				Component = DownloadToken
				break
			case 'REGISTER_PRODUCTS':
				Component = RegisterProduct
				break
			case 'RESET_PASSWORD':
				Component = ResetPassword
				break
			case 'GET_PIN':
				Component = GetPin
				break
			case 'REASSIGN_REGISTRATION':
				Component = Reassign
				break
			case 'CONFIRM':
				Component = Confirm
				break
			case 'PROMO':
				Component = Promo
				break
			case 'CONNECTPROMO':
				Component = ConnectPromo
				break
			case 'ADD_RAW_ID':
				Component = AddRawId
				break
		}
	}

	return (
		<dialog
			className={modalVisible ? 'modal visible' : 'modal'}
			onClick={closeModal}
		>
			{ Component &&
				<Component {...modalProps} />
			}
		</dialog>
	)
}

export default Modal