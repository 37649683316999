import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import FormContainer from 'components/forms/formContainer'
import { useDispatch } from 'react-redux'
import { requestPasswordReset } from 'state/actions'

export default function Login() {
    const [formValues, setFormValues] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [response, setResponse] = useState(null)
    const dispatch = useDispatch()

    const loginForm = [
        [
            {
                type: 'email',
                placeholder: 'Enter your email here',
                key: 'email'
            }
        ],
        [
            {
                type: 'button',
                label: 'Submit',
                key: 'login'
            }
        ]
    ]

    const handleChange = (key, value) => {
        const _formValues = { ...formValues }

        _formValues[key] = value

        setFormValues(_formValues)
    }

    const handleError = (key, value) => {
        setErrors({
            [key]: value
        })
    }

    const handleSubmit = () => {
        const { email } = formValues

        if(!email){
            return handleError(null, `Please enter an email address.`)
        }

        setLoading(true)

        dispatch(requestPasswordReset({
            email,
        }, callback => {
            setLoading(false)

            if (callback.type === 'FAILED') {
                const errorMessage = Object.keys(callback.data).length ? callback.data[Object.keys(callback.data)[0]][0] : 'Yikes.'
                
                return handleError(null,errorMessage)
            }else{
                setErrors({})
                return setResponse(`Success! Check your email to confirm.`)
            }
        }))
    }

    return (
        <div className='container-narrow '>
            <p className='copy large center'>
                Forgot your password? Enter your email below, and we'll send you a reset link.
			</p>
            <div className={'form-wrap'}>
                <FormContainer
                    rows={loginForm}
                    values={formValues}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    loading={isLoading}
                    errors={errors}
                    response={response}
                    hideLabels
                />
            </div>
            <p className='copy large top center'>
				<NavLink className='animate-link' to='/auth/login'>
                    Back to login
				</NavLink>
            </p>
        </div>
    )
}
