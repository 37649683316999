import { denormalize } from 'normalizr'
import * as schemas from 'state/schemas'

export const getCurrentUser = state => {
	const { main } = state

	if (main.currentUserId && main.entities.users[main.currentUserId]) {
		return main.entities.users[main.currentUserId]
	}

	return null
}

export const getUserAccountData = ( state, userId ) => {
	const { main } = state

	const user = ( userId && main.entities ) ? main.entities.users[userId] : getCurrentUser(state)

	if (!user) {
		return null
	}

	const denormalizedResponse = denormalize(
		user,
		schemas.userSchema,
		main.entities
	)

	return denormalizedResponse
}

export const getAllPosts = state => {
	const allPosts = []

	const { main } = state

	if (main?.entities?.posts) {
		Object.keys(main.entities.posts).forEach(postId => {
			const post = main.entities.posts[postId]

			allPosts.push(post)
		})
	}

	const denormalizedResponse = denormalize(
		allPosts.map(post => post.ID),
		[schemas.postsSchema],
		main.entities
	)

	return denormalizedResponse
}

export const getAcademyCategories = state => {
	const { main } = state

	if (main.academyCategoryIds) {
		return denormalize(
			main.academyCategoryIds,
			[schemas.categoriesSchema],
			main.entities
		)
	}

	return []
}

export const getFirmware = state => {
	return state.main.firmware || []
}

export const getResources = state => {
	return state.main.resources || []
}

export const getBrands = state => {
	return state.main.cameraBrands || []
}

export const getCtas = state => {
	return state.main.ctas || []
}

export const getHeroPosts = state => {
	return state.main.heroPosts || []
}

export const getMenuToggle = state => {
	return state.main.toggleMenu || []
}

export const getCurrentRegistration = (state, registrationId) => {
	const { entities } = state.main

	if (registrationId && entities.registrations[registrationId]) {
		const registration = entities.registrations[registrationId]

		return denormalize(registration, schemas.registrationsSchema, entities)
	}

	return null
}

export const getModalData = state => {
	return state.main.currentModal
}

export const getRegisterProducts = state => {
	const { main } = state
	const allProducts = []

	if (main?.entities?.products) {
		Object.keys(main.entities.products).forEach(productId => {
			allProducts.push(main.entities.products[productId])
		})
	}

	return allProducts
}

export const getProductFeature = state => {
	const { main } = state
	const allFeatures = []

	if (main?.entities?.features) {
		Object.keys(main.entities.features).forEach(featuresId => {
			allFeatures.push(main.entities.features[featuresId])
		})
	}

	return allFeatures
}
