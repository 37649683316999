import React, { useState, useRef, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useModal, isSupport } from 'utils'

import Slider from 'components/slider'
import FormContainer from 'components/forms/formContainer'
import countryList from 'react-select-country-list'
import RegisterForm from 'components/forms/registerForm'

import {
	registerUser,
	setUserInterests,
	getCameraBrands,
	getRegisterFormData,
} from 'state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccountData, getCurrentUser, getBrands } from 'state/selectors'

import { cloneDeep } from 'lodash'
import { emailIsValid } from 'utils'

import { useHistory } from 'react-router'

const Register = ({
	title,
	copy,
	isEdit,
	user,
	isAuth
}) => {
	const [formValues, setFormValues] = useState({
		hasAcceptedMarketing: true
	})
	const formValuesRef = useRef(formValues)
	formValuesRef.current = formValues

	const [loggedIn, setLoggedIn] = useState(false)

	const [errors, setErrors] = useState([{}, {}])
	const [loading, setLoading] = useState(false)
	const errorsRef = useRef(errors)
	errorsRef.current = errors

	const doModal = useModal()

	const dispatch = useDispatch()
	const history = useHistory()

	const countryOptions = useMemo(() => countryList().getLabels(), [])

	const _countryOptions = countryOptions.map(country => {
		return {
			label: country,
			value: country
		}
	})

	useEffect(() => {
		dispatch(getCameraBrands())
		dispatch(getRegisterFormData())
	}, [])

	const currentUser = useSelector(getCurrentUser)
	const userAccountData = useSelector(getUserAccountData)

	const CameraOptions = useSelector(getBrands)

	const _isSupport = isSupport(userAccountData)

	useEffect(() => {
		if (!isAuth) {
			if (user) {
				if (!formValuesRef.current.id) {
					setFormValues({ ...user })
				}
			} else if (currentUser) {
				if (!formValuesRef.current.id) {
					setFormValues({ ...currentUser })
				}
			} else {
				setFormValues({})
			}
		}
	}, [user, currentUser])

	const sliderRef = useRef(null)

	const handleChange = (key, value) => {
		const _formValues = { ...formValues }

		_formValues[key] = value

		setFormValues(_formValues)

		if (!isEdit) {
			setTimeout(() => {
				sliderRef.current.resize()
			}, 10)
		}
	}

	const _changeSlide = callback => {
		const {
			first_name,
			last_name,
			email,
			password,
			hasAcceptedTerms,
			country
		} = formValues

		const handleFirstSectionError = error => {
			handleError(null, error, 0)
		}

		if (!first_name || first_name === '') {
			return handleFirstSectionError(`Please enter a first name`)
		}

		if (!last_name || last_name === '') {
			return handleFirstSectionError(`Please enter a last name`)
		}

		if (!email) {
			return handleFirstSectionError(`Please enter an email address`)
		}

		if(!country){
			return handleFirstSectionError(`Please select your country`)
		}

		if (!emailIsValid(email)) {
			return handleFirstSectionError(
				`That email doesn't appear to be valid`
			)
		}

		if (!isEdit) {
			if (!password) {
				return handleFirstSectionError(`Please enter a password`)
			}

			if (password.length < 6) {
				return handleFirstSectionError(`Your password needs to be at least 6 digits`)
			}

			if (!hasAcceptedTerms) {
				return handleFirstSectionError(`Please accept our privacy policy.`)
			}
		}

		setLoading(true)

		dispatch(
			registerUser({ formValues, _isSupport }, response => {
				if (response.type === 'FAILED') {
					setLoading(false)

					const firstError =
						response.data[Object.keys(response.data)[0]]
					if (firstError && firstError.length) {
						handleFirstSectionError(firstError[0])
					}
				} else {
					if (isEdit) {
						callback()
					} else {
						setLoading(false)
						sliderRef.current.selectCell(1)
						setTimeout(() => {
							sliderRef.current.resize()
						}, 10)
						setLoggedIn(true)
					}
				}
			})
		)
	}

	const handleError = (key, value, formIndex) => {
		const _errors = cloneDeep(errors)
		_errors[formIndex][key] = value

		setErrors(_errors)

		if (!isEdit) {
			setTimeout(() => {
				sliderRef.current.resize()
			}, 10)
		}
	}

	const _skipForNow = () => {
		history.push('/')
	}

	const handleSubmit = () => {
		setLoading(false)

		if (isEdit) {
			_changeSlide(() => {
				const {
					preferred_camera,
					preferred_edit_software,
					user_type,
					country
				} = formValues

				const handleSecondSectionError = error => {
					handleError(null, error, isEdit ? 0 : 1)
				}

				if (!country) {
					return handleSecondSectionError('Please select your country')
				}

				if (!preferred_camera) {
					return handleSecondSectionError(`Please select your preferred camera brand.`)
				}

				if (!preferred_edit_software) {
					return handleSecondSectionError(`Please select your edit software.`)
				}

				if (!user_type) {
					return handleSecondSectionError(`Please select your user type`)
				}

				setLoading(true)

				dispatch(setUserInterests({ formValues, _isSupport }, response => {
					setLoading(false)

					if (response.type === 'SUCCESS') {
						if (isEdit) {
							doModal({
								type: null,
								data: {}
							})
						} else {
							history.push('/')
						}
					} else {
						const firstError =
							response.data[Object.keys(response.data)[0]]
						if (firstError && firstError.length) {
							handleSecondSectionError(firstError[0])
						}
					}
				}))

			})
		}
	}

	const registerProductSubmitCallback = (response, _handleError) => {
		setLoading(false)
		if (response) {
			if (response.type === 'FAILED') {
				if (response.data) {
					const firstError = response.data[Object.keys(response.data)[0]]

					if (firstError && firstError.length) {
						_handleError(null, typeof firstError === 'string' ? firstError : firstError[0])
					}
				} else {
					_handleError(null, `An unexpected error has occurred. Please contact support.`)
				}
			} else {
				history.push('/')
			}
		} else {
			sliderRef.current.selectCell(0)
			setTimeout(() => {
				sliderRef.current.resize()
			}, 10)
		}
	}

	const RegisterFormOne = [
		[
			{
				type: 'text',
				placeholder: 'First name',
				key: 'first_name'
			},
			{
				type: 'text',
				placeholder: 'Last name',
				key: 'last_name'
			}
		],
		[
			{
				type: 'email',
				placeholder: 'Email',
				key: 'email'
			}
		],
		[
			{
				type: 'select',
				label: 'Select your country',
				key: 'country',
				options: _countryOptions,
				placeholder: 'Search countries...'
			}
		],
		[
			{
				type: 'password',
				hidden: isEdit,
				placeholder: 'Password',
				key: 'password'
			}
		],
		[
			{
				type: 'checkbox',
				text: `I accept the terms detailed within MyAtomos <a href="https://atomos.com/myatomos-privacy-policy" target="_blank">privacy policy</a>.`,
				hidden: isEdit,
				key: 'hasAcceptedTerms'
			}
		],
		[
			{
				type: 'checkbox',
				text: `Atomos would like to occasionally contact you by email with information about our products and services we think you’ll find interesting. If you don’t want to hear from us, please untick this box.`,
				hidden: isEdit,
				key: 'hasAcceptedMarketing'
			}
		],
		[
			{
				type: 'button',
				hidden: isEdit,
				label: 'NEXT',
				key: 'nextSlide'
			}
		]
	]

	const EditSoftwareOptions = [
		{
			value: 'Apple Final Cut Pro',
			label: 'Apple Final Cut Pro'
		},
		{
			value: 'Adobe Premier Pro',
			label: 'Adobe Premier Pro'
		},
		{
			value: 'Avid Media Composer',
			label: 'Avid Media Composer'
		},
		{
			value: 'Grass Valley Edius',
			label: 'Grass Valley Edius'
		},
		{
			value: 'Davinci Resolve',
			label: 'Davinci Resolve'
		},
		{
			value: 'Other',
			label: 'Other'
		},
	]

	const Tiles = [
		{
			value: 'EDITOR_COLORIST',
			label: 'Editor/ Colorist'
		},
		{
			value: 'FILM_DOCUMENTARY',
			label: 'Film/ Documentary'
		},
		{
			value: 'HOBBYIST',
			label: 'Hobbyist'
		},
		{
			value: 'PHOTOGRAPHER',
			label: 'Photographer'
		},
		{
			value: 'STREAMING_AV',
			label: 'Streaming & AV'
		},
		{
			value: 'VIDEO_PROFESSIONAL',
			label: 'Video Professional'
		}
	]

	const RegisterFormTwoEdit = [
		[
			{
				type: 'select',
				label: 'Select your country',
				key: 'country',
				options: _countryOptions,
				placeholder: 'Search countries...'
			}
		],
		[
			{
				type: 'select',
				label: 'Preferred camera brand',
				key: 'preferred_camera',
				options: CameraOptions,
				placeholder: 'Search cameras...'
			},
			{
				type: 'select',
				label: 'Preferred edit software',
				key: 'preferred_edit_software',
				options: EditSoftwareOptions,
				placeholder: 'Search edit software...'
			}
		],
		[
			{
				type: 'tiles',
				key: 'user_type',
				label: 'Who are you?',
				tiles: Tiles
			}
		],
		[
			{
				type: 'button',
				label: !isEdit ? 'Register' : 'Update Details'
			}
		]
	]

	const formContainersJsx = (
		<>
			<FormContainer
				isEdit={isEdit}
				heading={title ? title : 'Welcome to My Atomos!'}
				copy={
					copy ||
					'Register to access feature upgrades, exclusive content and your product warranty.'
				}
				rows={RegisterFormOne}
				values={formValues}
				errors={errors[0]}
				loading={loading}
				handleChange={handleChange}
				handleSubmit={_changeSlide}
				hideLabels={!isEdit}
			/>
			{ isEdit ?
				<FormContainer
					heading={title ? null : 'Have a device to register?'}
					copy={null}
					rows={RegisterFormTwoEdit}
					values={formValues}
					errors={errors[1]}
					loading={loading}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					hideLoader={true}
				/>
				:
				<RegisterForm
					heading={title ? null : 'Have a device to register?'}
					formValues={formValues}
					errors={errors}
					handleChange={handleChange}
					loggedIn={loggedIn}
					_skipForNow={_skipForNow}
					submitCallback={registerProductSubmitCallback}
					sliderRef={sliderRef}
				/>
			}
		</>
	)

	return (
		<div className={`container-narrow`}>
			<div className={`form-wrap register`}>
				{isEdit ? (
					formContainersJsx
				) : (
					<Slider
						className={'form-slider-slideshow'}
						options={{
							prevNextButtons: false,
							pageDots: true,
							wrapAround: false,
							draggable: false,
							hash: true,
							adaptiveHeight: true
						}}
						flickityRef={ref => (sliderRef.current = ref)}
					>
						{formContainersJsx}
					</Slider>
				)}
			</div>
			{!isEdit ? (
				<p className='copy large top center'>
					Already have an account?
					<NavLink className='animate-link' to='/auth/login'>
						Login here.
					</NavLink>
				</p>
			) : null}
		</div>
	)
}

export default Register