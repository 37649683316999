import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRegistration, swapToOs11 } from 'state/actions'
import { getCurrentRegistration } from 'state/selectors'

import Cookies from 'js-cookie'

import {
	useMainContext,
	useModal,
	getTokenDownloadUrl,
	ownsFeature,
	ignoreDID,
} from 'utils'

import { Switch, Route, Redirect } from 'react-router-dom'

import Button from 'components/button'
import SingleRegistrationData from 'components/singleRegistration/data'
import Tabs from 'components/singleRegistration/tabs'
import SingleRegistrationFeatures from 'components/singleRegistration/features'
import SingleRegistrationWarranty from 'components/singleRegistration/warranty'

const SingleRegistration = ({
	match,
	_registrationId,
	isSupport,
	canEdit,
	canActivate,
	user
}) => {
	const { setIsHeaderTransparent } = useMainContext()

	const hasCheckedForUpgradeModal = useRef(false)

	const [isVisible, setIsVisible] = useState(false)
	const [availableFeatures, setAvailableFeatures] = useState(0)

	const dispatch = useDispatch()
	const registrationId = _registrationId ? _registrationId : match.params.registrationId

	const currentRegistration = useSelector(state => getCurrentRegistration(state, registrationId))
	const aid = currentRegistration ? currentRegistration.aid : null
	const did = currentRegistration ? currentRegistration.did : null
	const uses_pearson_activation = currentRegistration ? currentRegistration.product.uses_pearson_activation : null
	const uses_serial_activation = currentRegistration ? currentRegistration.product.uses_serial_activation : null

	const featureInstances = currentRegistration ? currentRegistration.feature_instances : null
	const lastFeatureInstance = featureInstances ? featureInstances.slice(-1).pop() : null

	const _ignoreDID = currentRegistration ? ignoreDID(currentRegistration.product_id) : false

	useEffect(() => {
		if (currentRegistration) {
			if (currentRegistration.product && !hasCheckedForUpgradeModal.current && currentRegistration.product.features.length) {
				hasCheckedForUpgradeModal.current = true

				if ((!isSupport || canEdit) && !currentRegistration.aid && !currentRegistration.product.uses_pearson_activation && !currentRegistration.product.uses_serial_activation) {
					setTimeout(() => {
						doModal({
							type: 'TEXT',
							data: {
								title: `On AtomOS 10.6 or above?`,
								copy: `Activated features work a little differently. Add in an AID to activate features.`,
								narrow: true,
								button: {
									onClick: () => {
										doModal({
											type: null,
											data: null
										})

										handleEdit(true)
									},
									label: 'Add AID',
									isInverted: true
								},
								buttonLow: {
									onClick: () => {
										doModal({
											type: null,
											data: null
										})
									},
									label: 'Skip for now',
								}
							}
						})
					}, 1000)
				}
			}

			setTimeout(() => {
				setIsVisible(true)
			}, 10)
		}

		const features = currentRegistration?.product.features
		const feature_instances = currentRegistration?.feature_instances

		const _availableFeatures = (() => {
			const _features = features ? features.filter(feature => {
				if (!aid && feature.price_usd && !currentRegistration?.product?.uses_pearson_activation) {
					return false
				}
				return true
			}) : []

			let response = 0

			if (_features.length) {
				_features.map((featureItem) => {
					const alreadyOwns = ownsFeature(feature_instances, featureItem, currentRegistration?.product)

					if (!alreadyOwns) {
						response++
					}
				})
			}

			return response
		})()

		setAvailableFeatures(_availableFeatures)

	}, [currentRegistration])

	const doModal = useModal()

	const handleDownloadToken = () => {
		const openModalParams = {
			type: 'DOWNLOAD_TOKEN',
			data: {
				title: 'Download token',
				copy: `Please click the button below to obtain your token.`,
				button: {
					label: 'Download token',
					url: getTokenDownloadUrl(currentRegistration.id, lastFeatureInstance.features_id),
					isInverted: true
				},
				featureId: lastFeatureInstance.features_id,
				registration: currentRegistration
			}
		}

		const hasTwoPRRs = (() => {
			const hasStandard = currentRegistration.feature_instances.find(featureInstance => featureInstance?.feature?.id === 3)
			const has8K = currentRegistration.feature_instances.find(featureInstance => featureInstance?.feature?.id === 5)

			return !!(hasStandard && has8K)
		})()

		if (hasTwoPRRs) {
			openModalParams.data.subSection = {
				title: `Trouble activating this token?`,
				copy: `We've noticed you have ProRes RAW and ProRes RAW 8K active - this can cause issues activating the token.<br /><br />If you're having trouble, please click the button below to recalibrate your token.`,
				button: {
					label: 'Recalibrate & download token',
					url: getTokenDownloadUrl(currentRegistration.id, lastFeatureInstance.features_id, true),
					isInverted: true
				}
			}
		}

		doModal(openModalParams)
	}

	const handleEdit = isAddAid => {
		doModal({
			type: 'REGISTER_PRODUCTS',
			data: {
				title: 'Edit details',
				copy: `Edit your details here.`,
				registration: currentRegistration,
				isAddAid,
				isSupport,
				user
			}
		})
	}

	const handleFindMy_ = isTokenActivation => {
		doModal({
			type: 'TEXT',
			data: {
				title: `Where is my ${isTokenActivation ? 'AID' : 'DID'}?`,
				subtitle: `For AtomOS ${isTokenActivation ? '10.6 and above' : '10.5 and below'}.`,
				copy: isTokenActivation
					? '<p> AtomOS 10.6 and above provides an updated activation menu located within the top main menu of your device. </p><ol> <li> Scroll the tabs to the left to reveal more option and select the activation tab will present both the AID and a QR code. </li><li> For products that are already registered in your my.atomos.com account, select the registered device, click edit details and add the AID to the product. </li><li> For new products you can add the AID to in the registration field. </li></ol> <p> Note: the AID is case sensitive so please be sure to accurately enter you code as it appears on the screen checking 0 and O and 1 and I <br/><br/> With the process complete you will see options for FREE codec activation and a new option available to purchase an activation key to add new functionality to your device. </p>'
					: '<p> AtomOS 9 and below: </p><ol> <li> Press the blue menu button </li><li> Press the information button </li></ol> <p> AtomOS 10.5 and below: </p><ol> <li> Selecting the codec you would like to activate will present both the DID and the activation menu. </li><li> If you need to locate the DID you can find this via the settings menu. </li><li> Without any tools activated press the options slider icon </li><li> Scroll the screen to the right to access the Information menu. </li><li> For registrations without a DID entry select the device and click edit details. </li><li> Enter the DID for the registered product. </li><li> For new devices, be sure to add the DID during registration. </li></ol> <p> With the DID attached to a your device you can select the codec to activate. This will generate a unique 4-digit pin to enter on your device. You will also receive an email with the code for confirmation. </p>'
			}
		})
	}

	const handleHowToActivate = isTokenActivation => {
		doModal({
			type: 'TEXT',
			data: {
				title: `Activating codecs on your device`,
				subtitle: isTokenActivation ? `For AtomOS 10.6 and above.` : `For AtomOS 10.5 and below.`,
				copy: isTokenActivation ? `
					<ol>
						<li>
							Place the token onto a drive via a USB Dock. Please download the provided activation file to your drive, and extract. When inserted into your unit, this will automatically open the activation page and prompt for installation.
						</li>
						<li>
							This method allows you to activate both codecs and add other activation keys, which will unlock further features on your device.  A single activation token will support all functions unlocked on your device via my.atomos.com.
						</li>
						<li>
							The activation token will remain in your account on my.atomos.com and also be emailed to your registered address.
						</li>
					</ol>
					<p>
						For an in-depth walkthrough of the feature activation process, please <a href="https://atomos.zendesk.com/hc/en-us/articles/4514756643983-My-Atomos-Feature-Activation-Guide" target="_blank">
							visit our FAQs
						</a>
					</p>
				` : `
					<ol>
						<li>
							Tap the Codec icon at the top of the screen to open the codec selection menu.
						</li>
						<li>
							Select the codec you would like to activate
							<ol>
								<li>
									For AtomOS9 and below the blue arrow next to DNx or ProRes RAW
								</li>
								<li>
								  For AtomOS10 and above tap to toggle through to the codec name you would like to activate and then press confirm.
								</li>
							</ol>
						</li>
						<li>
							When prompted, enter your 4-digit activation code above into the unlock codec page, then select OK / confirm 4.
						</li>
						<li>
							Your device will reboot and be reconfigured for your chosen codec, either DNx or ProRes RAW.
						</li>
						<li>
							Your chosen codec can now be freely selected from the codec option menu of your Atomos device. 
						</li>
					</ol>
					<h4>
						Switching between Apple ProRes and Avid DNxHD: 
					</h4>
					<p>
						When switching between these codec types pressing ‘OK’ or ‘Confirm’ will reboot the device into the relevant mode. Once within the selected codec you can select the compression types i.e. ProRes HQ, 422 etc. without rebooting the device but please make sure to press ‘Confirm’ or ‘OK’ to activate the change.
					</p>
				`
			}
		})
	}

	const [notice, setNotice] = useState(null)
	const noticeRef = useRef(notice)
	noticeRef.current = notice

	useEffect(() => {
		const dismissals = Cookies.get('dismissedNotices') ? JSON.parse(Cookies.get('dismissedNotices')) : []
		const hasDismissed = dismissals.includes(currentRegistration?.product_id)

		let notice = null

		if (!hasDismissed) {
			switch (currentRegistration?.product_id) {
				case 159:
				case 145:
				case 153:
				case 155:
				case 154:
					notice = {
						title: `Upgraded your ${currentRegistration.product.product_name} to AtomOS 11?`,
						copy: `New features are available on AtomOS 11. Swap below if you've upgraded your device OS.`,
						button: {
							label: 'Swap to AtomOS 11',
							onClick: () => {
								doModal({
									type: 'CONFIRM',
									data: {
										title: `Swap to AtomOS 11?`,
										copy: `Please ensure your device has been upgraded to AtomOS 11.`,
										callback: () => {
											dispatch(swapToOs11(currentRegistration))
										}
									}
								})
							}
						}
					}
					break
				case 175:
				case 176:
				case 177:
				case 178:
				case 179:
					notice = {
						title: `Still on AtomOS 10 or below?`,
						copy: `Restore your original features if you've accidentally swapped to AtomOS 11's features and your device is still on AtomOS 10 or below.`,
						button: {
							label: 'Swap back to AtomOS 10',
							onClick: () => {
								doModal({
									type: 'CONFIRM',
									data: {
										title: `Swap back to AtomOS 10?`,
										copy: `This will restore the features available for AtomOS 10.`,
										callback: () => {
											dispatch(swapToOs11(currentRegistration))
										}
									}
								})
							}
						},
						buttonLow: {
							label: 'Dismiss',
							onClick: () => {
								dismissals.push(currentRegistration.product_id)
								Cookies.set('dismissedNotices', JSON.stringify(dismissals))

								setNotice(null)
							}
						}
					}
					break
			}
		}

		if (JSON.stringify(noticeRef.current) !== JSON.stringify(notice)) {
			setNotice(notice)
		}
	}, [currentRegistration])

	const tabs = !currentRegistration ? [] : [
		{
			label: 'Features',
			value: 'features',
			availableFeatures: availableFeatures > 0 && availableFeatures,
			preTitle: notice ? (
				<div className='features-alert'>
					<h3>
						{notice.title}
					</h3>
					<p>
						{notice.copy}
					</p>
					<div>
						<Button
							{...notice.button}
							inverted
						/>
						{notice.buttonLow &&
							<Button
								{...notice.buttonLow}
							/>
						}
					</div>
				</div>
			) : null,
			title: `Available features for ${currentRegistration.product.product_name}`,
			copy: `Activate codecs and purchase additional Activation keys to unlock additional features of your Atomos device.`,
			// hidden: _ignoreDID,
			buttons: (() => {
				const _buttons = []

				if (lastFeatureInstance) {
					const showTokenActivationInstructions = (aid || (!aid && !did))

					_buttons.push({
						label: 'How to activate',
						onClick: () => handleHowToActivate(showTokenActivationInstructions)
					})
				}

				if (!uses_serial_activation) {

					if (!aid && !did) {
						_buttons.push({
							onClick: () => handleFindMy_(false),
							label: 'Where to find my DID?',
							subLabel: '(AtomOS 10.5 and below)'
						})
					}

					if (!aid && !uses_pearson_activation) {
						_buttons.push({
							onClick: () => handleFindMy_(true),
							label: 'Where to find my AID?',
							subLabel: '(AtomOS 10.6 and above)'
						})
					}
				}

				if (lastFeatureInstance && (aid || uses_serial_activation)) {
					_buttons.push({
						label: 'Download Token',
						onClick: handleDownloadToken,
						isInverted: true
					})
				}

				return _buttons
			})(),
			Component: SingleRegistrationFeatures
		},
		{
			label: 'Warranty Options',
			value: 'warranty',
			title: null,
			copy: (!isSupport || canEdit) ? `Activate extended warranty below.` : ``,
			hidden: (() => {
				if (!currentRegistration) {
					return true
				}
			})(),
			Component: SingleRegistrationWarranty
		},
	]

	useEffect(() => {
		setIsHeaderTransparent(false)

		if (registrationId) {
			dispatch(
				getRegistration({
					registrationId
				}, response => {
					if (!response.data || response.type === 'FAILED') {
						doModal({
							type: 'TEXT',
							data: {
								title: `Error`,
								copy: `You don't appear to have access to this registration.`
							}
						})
					}
				})
			)
		}

		window.scroll({ top: 0, left: 0, behavior: 'smooth' })
	}, [])

	const headerButtonProps = (() => {
		const response = []

		if (!lastFeatureInstance || isSupport) {
			response.push({
				onClick: () => handleEdit(false),
				label: `Edit details`,
				edit: true
			})
		} else if (!uses_pearson_activation && !aid) {
			response.push({
				onClick: () => handleEdit(true),
				label: `Add AID`,
				edit: true
			})
		} else {
			response.push({
				onClick: () =>
					doModal({
						type: 'TEXT',
						data: {
							title: `Please contact support.`,
							copy: `You can't edit this product's details as you have active features.`,
							button: {
								onClick: () =>
									doModal({
										type: null,
										data: {}
									}),
								label: `Okay`,
								isInverted: true
							}
						}
					}),
				label: `Edit details`,
				edit: true
			})
		}

		return response
	})()

	const visibleTabs = tabs.filter(tab => !tab.hidden)

	return (
		<div className={`singleRegistration ${isVisible ? 'mounted' : ''}`}>
			<div className='singleRegistration-main'>
				<Button
					url={(isSupport && user) ? '/customers/' + user.id : '/my-products'}
					label='Back to devices'
					naked
					back
				/>
				{(currentRegistration?.product) && (
					<div className='singleRegistration-main-cont'>
						<div className='singleRegistration-image-cont'>
							<img src={`/products/${currentRegistration.product.product_url}.png`} />
						</div>
						<div className='singleRegistration-details'>
							<div className='singleRegistration-title'>
								<h2 dangerouslySetInnerHTML={{ __html: currentRegistration.product.product_name }} />
								{(!isSupport || canEdit) ?
									headerButtonProps.map((button, index) => (
										< Button key={index} {...button} compact />
									))
									: null}
							</div>
							<SingleRegistrationData
								registration={currentRegistration}
							/>
						</div>
						<div className={'singleRegistration-tabs-cont'}>
							<Tabs
								tabs={visibleTabs}
								routeSlug={(isSupport && user && currentRegistration.id) ? `/customers/${user.id}/${currentRegistration.id}` : `/my-products/${currentRegistration.id}`}
							/>
							<div className={`singleRegistration-sections`}>
								<Switch>
									{visibleTabs.map(({
										value,
										Component,
										preTitle,
										title,
										copy,
										buttons,
									}) => (
										<Route
											key={value}
											path={
												match.url + '/' + value
											}
											render={params => (
												<div
													className={'inner'}
												>
													{preTitle && preTitle}
													<div className='singleRegistration-title'>
														{title ?
															<div className={'title-inner'}>
																<h3 dangerouslySetInnerHTML={{ __html: title }} />
																<div
																	className={'copy'}
																	dangerouslySetInnerHTML={{ __html: copy }}
																/>
															</div>
															: null}
														{buttons &&
															(buttons.length > 0) &&
															<div className='singleRegistration-title-buttons'>
																{buttons.map(({
																	url,
																	onClick,
																	label,
																	subLabel,
																	isInverted,
																	edit
																}) => {
																	if (!label) {
																		return null
																	}

																	return (
																		<Button
																			key={label}
																			onClick={onClick}
																			url={onClick ? '' : url}
																			label={label}
																			subLabel={subLabel}
																			inverted={!!isInverted}
																			edit={edit}
																		/>
																	)
																}
																)}
															</div>
														}
													</div>
													<Component
														registration={currentRegistration}
														isSupport={isSupport}
														canEdit={canEdit || !isSupport}
														canActivate={canActivate || !isSupport}
														user={user}
														{...params}
													/>
												</div>
											)}
										/>
									))}
									<Redirect
										to={`${match.url}/${visibleTabs[0].value}`}
									/>
								</Switch>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default SingleRegistration