import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'

import { doPearsonActivation } from 'state/actions'

import Button from 'components/button'

export default function TextModal({
    type,
    data,
    closeModal
}) {
    const {
        registration,
        feature,
        currentUser,
        isNew,
    } = data

    const [code, setCode] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(doPearsonActivation({
            registrationId: registration.id,
            featureId: feature.id
        }, callback => {
            const code = callback.data.code

            setCode(code)
        }))
    }, [])

    const nameToDisplay = feature.display_name || feature.name

    return (
        <div className={`${type} narrow`} onClick={e => e.stopPropagation()}>
            <X className="modal-close" onClick={closeModal} />
            <div>
                {code ?
                    <>
                        <h3>
                            {isNew ? 'Activation Successful!' : `Activation Code`}
                        </h3>
                        <div dangerouslySetInnerHTML={{__html: isNew ?
                                `We've successfully activated ${nameToDisplay} for your ${registration.product.product_name}. Your activation code is <strong>${code}</strong>.<br /><br />
                                We've emailed this, along with activation instructions to ${currentUser.email}.`
                                :
                                `Your activation code for ${nameToDisplay} on your ${registration.product.product_name} is: <strong>${code}</strong>.<br /><br />
                                We've re-sent activation instructions to ${currentUser.email}, so check this inbox for activation instructions.`
                        }} />
                        <Button
                            onClick={closeModal}
                            label={`Okay`}
                            inverted={true}
                        />
                    </>
                    :
                    <>
                        <h3>
                            One sec...
                        </h3>
                        <div>
                            We're getting your activation code now.
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
