import React from 'react'
import { useModal } from 'utils'
import { useMainContext } from 'utils'

export default function UserButtonMenu({ visible }) {
	const doModal = useModal()

	const {
		logOut
	} = useMainContext()

	const openProfileModal = () => {
		doModal({
			type: 'EDIT_PROFILE',
			data: {
				title: 'Account details',
				copy: `Edit your details here.`,
			},
		})
	}

	const openResetPasswordModal = () => {
		doModal({
			type: 'RESET_PASSWORD',
			data: {
				title: 'Account details',
				copy: `Edit your details here.`,
			},
		})
	}

	const links = [
		{
			label: 'Edit profile',
			onClick: openProfileModal
		}, {
			label: 'Reset password',
			onClick: openResetPasswordModal
		}, {
			label: 'Log out',
			onClick: logOut
		}
	]

	return (
		<div className={`user-button-menu ${visible ? 'visible' : ''}`}>
			<ul>
				{links.map(({
					label,
					onClick
				}) => (
					<li key={label}>
						<button onClick={onClick}>
							{label}
						</button>
					</li>
				))}
			</ul>
		</div>
	)
}
