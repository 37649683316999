import React, { useState, useRef, useEffect } from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'
import { purchaseFeature, activateFreeFeature, validateCoupon } from 'state/actions'
import { useModal, getPrice, doWordPressGraphQLRequest } from 'utils'
import loadingImage from 'assets/images/loading.svg'

import FadeCollapse from 'components/fadeCollapse'

import CardForm from 'components/cardForm'

import Select from 'react-select'
import Button from 'components/button'

import { debounce } from 'lodash'

export default function CodecModal({ type, data, closeModal }) {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [couponCode, setCouponCode] = useState('')
	const [couponResponse, setCouponResponse] = useState(null)
	const [couponData, setCouponData] = useState(null)
	const [isCouponDataLoading, setIsCouponDataLoading] = useState(false)

	const [rawId, setRawId] = useState('')

	const [cameraData, setCameraData] = useState(null)
	const [selectedBrandId, setSelectedBrandId] = useState(null)
	const [selectedCameraId, setSelectedCameraId] = useState(null)

	const preventSubmission = useRef()

	const doModal = useModal()

	const {
		feature,
		owns,
		isToken,
		registration,
		isSupport,
		currentUser
	} = data

	const {
		id,
		name,
		display_name,
		description,
		brief_description,
		free_with_raw_id,
		image,
	} = feature

	const nameToShow = display_name || name

	const price = getPrice(feature, registration, isSupport)
	const requiresRawId = free_with_raw_id && price // price is 0 if legacy OR RAW ID exists

	const shouldShowCameraSelector = price && feature?.id === 3 // if PRR isn't free, then you'll need to select a camera

	useEffect(() => {
		if (shouldShowCameraSelector) {
			doWordPressGraphQLRequest(`
				query {
					brands (first: 999) {
						nodes {
							databaseId
							name
							cameras (first: 999) {
								nodes {
									title
									databaseId
									cameraFields {
										supportsProresRaw
									}
								}
							}
						}
					}
				}
			`).then(({ data }) => {
				const { nodes } = data.data.brands

				const filteredNodes = [...nodes]

				filteredNodes.forEach(brand => {
					brand.cameras.nodes = brand.cameras.nodes.filter(camera => camera.cameraFields.supportsProresRaw)
				})

				setCameraData(filteredNodes.filter(brand => brand.cameras.nodes.length))
			}).catch(error => {
				console.log(error)
			})
		}
	}, [])

	const dispatch = useDispatch()

	const handleCouponResponse = (isSuccess, message, _couponData) => {
		setCouponResponse({
			type: isSuccess ? 'SUCCESS' : 'ERROR',
			text: message
		})

		setCouponData(isSuccess ? _couponData : null)
	}

	const handleCheckCoupon = value => {
		if (value) {
			setIsCouponDataLoading(true)

			validateCoupon(value).then(({ data }) => {
				console.log(data)
				
				setIsCouponDataLoading(false)

				const {
					applies_to,
					amount_off,
					percent_off,
					valid
				} = data

				if (!valid) {
					return handleCouponResponse(false, `This code is no longer valid.`)
				}

				const appliesToFeature = (!applies_to?.products?.length) || applies_to.products.find(stripeProductId => {
					return stripeProductId === feature.stripe_id
				})

				if (!appliesToFeature) {
					return handleCouponResponse(false, `This coupon doesn't apply to this feature.`)
				}

				return handleCouponResponse(true, `Coupon applied for ${amount_off ? '$' : ''}${(amount_off / 100) || percent_off}${percent_off ? '%' : ''} discount.`, data)
			}).catch(error => {
				console.log(error)

				return handleCouponResponse(false, `This code doesn't appear to be valid.`)
			})
		} else {
			setCouponResponse(null)
			setCouponData(null)
		}
	}

	const debouncedHandleCheckCoupon = useRef(debounce(handleCheckCoupon, 500))

	const handleCouponChange = e => {
		const _value = e.target.value.toUpperCase()

		setCouponCode(_value)
		debouncedHandleCheckCoupon.current(_value)
	}

	const handleSuccess = () => {
		if (isToken) {
			closeModal()

			setTimeout(() => {
				doModal({
					type: 'TEXT',
					data: {
						title: 'Purchase Successful',
						copy: `You have successfully activated ${nameToShow} support for your ${registration.product.product_name}<br /><br />Once you've activated every feature/codec you want to use on your Atomos product, click <strong>Download token</strong> in the right corner of your device's feature overview to obtain a unified token that activates them all at once.`,
					}
				})
			}, 300)
		}
	}

	const handleFreeActivation = () => {
		const registrationId = registration.id
		const featureId = id

		setLoading(true)

		if (isToken) {
			return dispatch(activateFreeFeature({ featureId, registrationId }, _ => {
				setLoading(false)

				handleSuccess()
			}))
		}

		doModal({
			type: 'GET_PIN',
			data: {
				registration,
				feature,
				currentUser,
				isNew: true
			}
		})
	}

	const handlePurchase = response => {
		const { type, data } = response

		if (preventSubmission.current) {
			return
		}

		preventSubmission.current = true

		if (type === 'FAILED') {
			setLoading(false)
			preventSubmission.current = false
			return setError(data.message)
		}

		if(shouldShowCameraSelector && (!selectedBrandId || !selectedCameraId)) {
			setLoading(false)
			preventSubmission.current = false
			return setError(`Please select your camera ${ !selectedBrandId ? 'manufacturer' : 'model'}.`)
		}

		const { token, addingNewPaymentMethod, saveCard } = data

		let requestData = {
			registrationId: registration.id,
			featureId: feature.id,
			addingNewPaymentMethod,
			couponData,
			selectedBrandId,
			selectedCameraId,
			rawId,
			price,
			saveCard
		}

		if (addingNewPaymentMethod) {
			if (!token) {
				setLoading(false)
				preventSubmission.current = false
				return setError(`An unexpected error has occurred. Please contact support.`)
			}

			requestData = {
				...requestData,
				stripeCardToken: token.id,
				stripeCardBrand: token.card.brand,
				stripeCardLast4: token.card.last4
			}
		}

		dispatch(purchaseFeature(requestData, response => {
			preventSubmission.current = false
			setLoading(false)

			const { type, data } = response

			if (type === 'FAILED') {
				return setError(data || `An unexpected error has occurred. Please contact support.`)
			}

			handleSuccess()
		}))
	}

	const buyLabel = (() => {
		if (isSupport) {
			return `Activate for ${currentUser.first_name}`
		} else {
			let _price = price

			if (couponData) {
				const {
					amount_off,
					percent_off
				} = couponData

				if (amount_off) {
					_price -= (amount_off / 100)
				} else if (percent_off) {
					_price *= (100 - percent_off) / 100
				}
			}

			if(rawId){
				_price = 0
			}

			return `Activate now (${_price ? `US$${_price.toFixed(2)}` : 'FREE'})`
		}
	})()

	const _description = (() => {
		let response = description

		if (feature.id === 1 && registration.product_id === 153) {
			response = `
				<p>
					This activation key will unlock:
				</p>
				<strong>
					RAW input via SDI for following cameras:
				</strong>
				<ul>
					<li>
						Pansonic Varicam LT and EVA1
					</li>
					<li>
						Sony FX6 and FX9 with XDCA-FX9
					</li>
					<li>
						Sony FS5mki*/mkii, FS700*/R or FS7mki/mkii with XDCA-FX7
					</li>
					<li>
						Canon C300mkii and C500
					</li>
				</ul>
				<p>
					RAW inputs via HDMI can converted for output SDI up to 12G in YCbCr 10bit 422 Video
				</p>
				<ul>
					<li>
						Non-standard frame sizes are output in SMPTE standard resolutions
					</li>
				</ul>
				<p>
					NOTE: Sony FS5mki and FS700 require a paid up grade for the camera available from Sony.
				</p>
			`
		}

		if (isToken) {
			response += `
                <p>
                    Activation tokens are tied to the device AID and cannot be used to activate another device.
                </p>
            `
		}

		return response
	})()

	return (
		<div
			className={`${type} ${loading && 'loading'}`}
			onClick={e => e.stopPropagation()}
		>
			<X className='modal-close' onClick={closeModal} />
			{feature && (
				<div>
					<div className='top'>
						<div className={type + '-icon-cont'}>
							<div className={!image ? type + '-icon noImage' : type + '-icon'} style={{ backgroundImage: `url(${image})` }} />
						</div>
						<div className={type + '-details'}>
							<img src={loadingImage} className={`loader`} />
							<div
								className={type + '-title'}
								dangerouslySetInnerHTML={{ __html: 'Codec' }}
							/>
							<h3 dangerouslySetInnerHTML={{ __html: nameToShow }} />
							<div
								className={type + '-description'}
								dangerouslySetInnerHTML={{
									__html: brief_description
								}}
							/>
							{error && (
								<div className={'form-error'}>
									<i
										className={'fa fa-exclamation-triangle'}
									/>
									<span>{error}</span>
								</div>
							)}
							{(shouldShowCameraSelector) ?
								<div className={`${type}-camera-selector`}>
									<Select
										className='react-select-container'
										classNamePrefix='react-select'
										options={(cameraData || []).map(brand => ({
											value: brand.databaseId,
											label: brand.name
										}))}
										value={(() => {
											if (!selectedBrandId || !cameraData) {
												return null
											}

											return {
												value: selectedBrandId,
												label: cameraData.find(brand => brand.databaseId === selectedBrandId)?.name
											}
										})()}
										placeholder={cameraData ? `Select camera manufacturer` : `Loading camera manufacturers...`}
										onChange={event => {
											setSelectedBrandId(event.value)
										}}
									/>
									<Select
										className='react-select-container'
										classNamePrefix='react-select'
										options={(cameraData || []).find(brand => brand.databaseId === selectedBrandId)?.cameras?.nodes?.map(camera => ({
											value: camera.databaseId,
											label: camera.title
										}))}
										value={(() => {
											if (!selectedCameraId || !cameraData) {
												return null
											}

											return {
												value: selectedCameraId,
												label: cameraData.find(brand => brand.databaseId === selectedBrandId)?.cameras?.nodes?.find(camera => camera.databaseId === selectedCameraId)?.title
											}
										})()}
										placeholder={`Select camera model`}
										onChange={event => {
											setSelectedCameraId(event.value)
										}}
										isDisabled={!selectedBrandId}
									/>
									<input 
										type="text" 
										placeholder="RAW ID (If activating for free)" 
										value={rawId}
										onChange={e => setRawId(e.target.value)}
									/>
								</div>
								: null}
							{price ? (
								<CardForm
									buttonLabel={buyLabel}
									currentUser={currentUser}
									handleSubmit={handlePurchase}
									setLoading={setLoading}
									couponCode={couponCode}
									handleCouponChange={handleCouponChange}
									couponResponse={couponResponse}
									isCouponDataLoading={isCouponDataLoading}
								/>
							) : (
								<Button
									label={buyLabel}
									inverted
									success={!!owns}
									onClick={handleFreeActivation}
								/>
							)}
						</div>
					</div>
					<div className='bottom'>
						<FadeCollapse open={requiresRawId}>
							<div className={'activate-alert'}>
								<img src={'/images/camera-alert.png'} />
								<div>
									<h4>
										Get this feature for free by supplying a RAW ID
									</h4>
									<p>
										Enter the 'Activation' menu on your device, press the 'Go To RAW' button and connect a compatible camera capable of outputting a RAW signal to your device.
										<br /><br />
										You can then scan the Activation QR code to update your registered product, and this feature will now be available for free.
									</p>
								</div>
							</div>
						</FadeCollapse>
						<div
							className={type + '-description'}
							dangerouslySetInnerHTML={{ __html: _description }}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
