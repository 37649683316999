import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { X } from 'react-feather'
import { cloneDeep } from 'lodash'

import FormContainer from 'components/forms/formContainer'

import { resetPassword } from 'state/actions'

export default function RegisterProduct({
	closeModal,
	type,
	data,
}) {
	const { 
		title, 
		copy,
	} = data
	
	const dispatch = useDispatch()

	const [formValues, setFormValues] = useState({})
	const [errors, setErrors] = useState([{}, {}])
	const [loading, setLoading] = useState(false)

	const handleSubmit = () => {
		const {
            oldPassword,
            newPassword,
            confirmNewPassword
		} = formValues

		if(!oldPassword){
			return handleError(null, `Please enter your current password.`)
		}

		if(!newPassword){
			return handleError(null, `Please enter a new password.`)
		}

		if(!confirmNewPassword){
			return handleError(null, `Please confirm your new password.`)
		}

		if(newPassword !== confirmNewPassword){
			return handleError(null, `Your confirm password doesn't match.`)
		}

		setLoading(true)

		dispatch(resetPassword(formValues, response => {
			setLoading(false)

			if (response.type === 'FAILED') {
				const firstError = response.data[Object.keys(response.data)[0]]

				if (firstError && firstError.length) {
					handleError(null, firstError[0])
				}
			} else {
				closeModal()
			}
		}))
	}

	const handleError = (key, value) => {
		const _errors = cloneDeep(errors)
		_errors[key] = value

		setErrors(_errors)
	}

	const handleChange = (key, value) => {
		const _formValues = { ...formValues }

		_formValues[key] = value

		setFormValues(_formValues)
	}

	const rows = [
		[
			{
				type: 'password',
				key: 'oldPassword',
				placeholder: `Enter your current password`,
			},
		],
		[
			{
				type: 'password',
				key: 'newPassword',
				placeholder: `Enter your new password`,
			},
		],
		[
			{
				type: 'password',
				key: 'confirmNewPassword',
				placeholder: `Confirm your new password`,
			},
		],
		[
			{
				type: 'button',
				label: 'SUBMIT',
				key: 'submit',
			},
		],
	]

	return (
		<div className={type} onClick={e => e.stopPropagation()}>
			<X className='modal-close' onClick={closeModal} />
			<div>
				<FormContainer
					heading={title}
					copy={copy}
					rows={rows}
					errors={errors}
					values={formValues}
					loading={loading}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
				/>
			</div>
		</div>
	)
}
