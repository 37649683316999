import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'

import FormContainer from 'components/forms/formContainer'
import { useDispatch } from 'react-redux'
import { confirmPasswordReset } from 'state/actions'

export default function Login() {
    const [formValues, setFormValues] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [response, setResponse] = useState(null)
    const dispatch = useDispatch()

    const location = useLocation()

    useEffect(() => {
        const { search } = location

        if(!search){
            return handleError(null, `No token.`)
        }

        const token = search.replace('?token=','')

        setFormValues({
            token
        })
    }, [])

    const loginForm = [
        [
            {
                type: 'password',
                placeholder: 'Enter a new password',
                key: 'password'
            }
        ],
        [
            {
                type: 'password',
                placeholder: 'Confirm your new password',
                key: 'confirmPassword'
            }
        ],
        [
            {
                type: 'button',
                label: 'Reset password',
                key: 'login'
            }
        ]
    ]

    const handleChange = (key, value) => {
        const _formValues = { ...formValues }

        _formValues[key] = value

        setFormValues(_formValues)
    }

    const handleError = (key, value) => {
        setErrors({
            [key]: value
        })
    }

    const handleSubmit = () => {
        const { password, confirmPassword, token } = formValues

        if(!password){
            return handleError(null, `Please enter a new password.`)
        }

        if(!confirmPassword){
            return handleError(null, `Please confirm your new password.`)
        }

        if(password !== confirmPassword){
            return handleError(null, `These passwords don't match.`)
        }

        setLoading(true)


        dispatch(confirmPasswordReset({
            password,
            token
        }, callback => {
            setLoading(false)

            if (callback.type === 'FAILED') {
                return handleError(null,callback.data)
            }else{
                setErrors({})
                return setResponse(`Success! Head back to login to log into your account.`)
            }
        }))
    }

    return (
        <div className='container-narrow '>
            <p className='copy large center'>
                Enter a new password below.
			</p>
            <div className={'form-wrap'}>
                <FormContainer
                    rows={loginForm}
                    values={formValues}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    loading={isLoading}
                    errors={errors}
                    response={response}
                    hideLabels
                />
            </div>
            <p className='copy large top center'>
				<NavLink className='animate-link' to='/auth/login'>
                    Back to login
				</NavLink>
            </p>
        </div>
    )
}
