import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
	Home,
	ShoppingBag,
	Bookmark,
	HelpCircle,
} from 'react-feather'
import { useSelector } from 'react-redux'
import { getMenuToggle } from 'state/selectors'
import { ToggleMenu } from 'utils'

import UserButton from 'components/userButton'

import logo from 'assets/images/myatomos-logo.png'

const Navbar = ({ user }) => {
	const [hasMounted, setHasMounted] = useState(false)
	const doToggleMenu = ToggleMenu()
	const menuToggle = useSelector(getMenuToggle)

	const isMenuActive = menuToggle.data ? menuToggle.data.active : false

	useEffect(() => {
		setHasMounted(true)
	}, [])

	const handleToggleMenu = () => {
		doToggleMenu({
			data: {
				active: isMenuActive ? false : true
			}
		})
	}

	const routes = [
		{
			path: '/',
			Icon: Home,
			isExact: true,
			label: 'Dashboard',
			hidden: true
		},
		{
			path: '/my-products',
			Icon: ShoppingBag,
			isExact: false,
			label: 'My Devices',
			hidden: true
		},
		{
			path: '/academy',
			Icon: Bookmark,
			isExact: false,
			label: 'Academy',
			hidden: true
		}, {
			path: 'https://atomos.cloud',
			iconUrl: `/images/atomos-cloud.png`,
		}
	]

	return (
		<aside
			className={
				hasMounted ? (isMenuActive ? 'mounted active' : 'mounted') : ''
			}
		>
			<section className='top' onClick={handleToggleMenu}>
				<NavLink exact to='/'>
					<img src={logo} alt='Atomos' />
				</NavLink>
				{routes.map(({
					iconUrl,
					path,
					Icon,
					isExact,
					label,
					hidden
				}) => {
					if(hidden){
						return null
					}

					const isExternal = path.startsWith('http')

					const inner = (
						<>
							{iconUrl ?
								<img src={iconUrl} />
								:
								<Icon size={17} />
							}
							{label || ''}
						</>
					)

					if (isExternal) {
						return (
							<a 
								href={path}
								key={path}
							>
								{inner}
							</a>
						)
					}

					return (
						<NavLink exact={isExact} to={path} key={path}>
							{inner}
						</NavLink>
					)
				})}
			</section>
			<section className='bottom'>
				<UserButton user={user} />
				<div onClick={handleToggleMenu}>
					<a href={`https://atomos.com/support`} target='_blank'>
						<HelpCircle size={20} />
						Support
					</a>
				</div>
			</section>
		</aside>
	)
}

export default Navbar