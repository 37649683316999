import React, { useState } from 'react'
import { getRegisterProducts } from 'state/selectors'

import {
    registerProduct,
} from 'state/actions'

import { cloneDeep } from 'lodash'

import { useSelector, useDispatch } from 'react-redux'

import { usesAid, ignoreDID, usesRawId } from 'utils'

import FormContainer from 'components/forms/formContainer'

const RegisterForm = ({
    heading,
    copy,
    formValues,
    handleChange,
    loggedIn,
    _skipForNow,
    submitCallback,
    sliderRef,
    isAddAid
}) => {
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const handleError = (key, value) => {
        const _errors = cloneDeep(errors)
        _errors[key] = value

        setErrors(_errors)

        if (sliderRef) {
            setTimeout(() => {
                sliderRef.current.resize()
            }, 10)
        }
    }

    const handleSubmit = () => {
        const {
            product_id,
            date_of_purchase,
            serial_number,
            did,
            aid,
            dealer_name,
        } = formValues

        if (!product_id) {
            return handleError(null, `Please select a product.`)
        }

        if (!serial_number) {
            return handleError(null, `Please enter a serial number.`)
        }

        if (!ignoreDID(product_id)) {
            if (!aid && !did) {
                if (usesAid(formValues.product_id)) {
                    return handleError(null, `Please enter either an AID or DID.`)
                } else {
                    return handleError(null, `Please enter a DID.`)
                }
            }
        }

        if (!date_of_purchase) {
            return handleError(null, `Please select your date of purchase.`)
        }

        if (!dealer_name) {
            return handleError(null, `Please add your Atomos dealer.`)
        }

        if (loggedIn) {
            setLoading(true)

            dispatch(registerProduct(formValues, response => {
                setLoading(false)
                submitCallback(response, (errorKey, errorValue) => {
                    handleError(errorKey, errorValue)
                })
            }))
        } else {
            setLoading(false)
            submitCallback(null)
        }

    }

    const products = useSelector(getRegisterProducts)

    const currentProductIds = [159, 166, 168, 153, 145, 154, 155, 151, 152, 146, 157, 149, 170, 171, 172, 173, 174, 181, 182, 183, 184, 185]
    const hiddenProductIds = [175]

    const productsOptions = [...products.filter(product => !hiddenProductIds.includes(product.id)), {
        id: -1,
        product_name: 'Legacy Products ---------',
        product_group: 'Recorders/Monitors',
        order: 0,
        disabled: true
    }].map(item => {
        const { product_name, product_group, disabled } = item

        if (product_group === 'Recorders/Monitors') {
            return {
                value: item.id,
                label: product_name,
                order: item.order,
                disabled
            }
        }
    }).filter(Boolean).sort((a, b) => {
        if (a.order < b.order) { return -1 }
        if (a.order > b.order) { return 1 }
        return 0
    }).sort((a, b) => {
        const isCurrentA = currentProductIds.includes(a.value)
        const isCurrentB = currentProductIds.includes(b.value)

        if (isCurrentA && !isCurrentB) { return -1 }
        if (!isCurrentA && isCurrentB) { return 1 }
        return 0
    })

    const rows = [
        [
            {
                type: 'select',
                key: 'product_id',
                options: productsOptions,
                placeholder: 'Select your product',
                label: `Select your product`,
                readOnly: !!isAddAid
            }
        ],
        [
            {
                type: 'text',
                label: `Serial number${formValues.product_id === 185 ? ' (Controller)' : ''}`,
                placeholder: 'Serial number',
                key: 'serial_number',
                readOnly: !!isAddAid
            }
        ],
        [
            {
                type: 'text',
                label: `Device ID (DID)`,
                placeholder: 'Device ID (DID)',
                hidden: ignoreDID(formValues.product_id),
                key: 'did',
                tooltip: {
                    label: `Where do I find this?`,
                    copy: `To locate your Device ID, touch the timecode display and then navigate to the INFO tab. Your device ID is displayed here. Be sure to check and enter the correct digits with O and 0 or I and 1.`
                },
                readOnly: !!isAddAid
            }
        ],
        [
            {
                type: 'text',
                label: `Device AID`,
                placeholder: 'Device AID',
                hidden: !usesAid(formValues.product_id),
                key: 'aid',
                tooltip: {
                    label: `Where do I find this?`,
                    copy: `To locate your AID, touch the timecode display and then navigate to the Activation tab. The AID is displayed here. This applies to devices with <strong>AtomOS firmware versions of 10.6 and above</strong>. Please note that the input is case sensitive, so care should be taken to accurately enter the code exactly as it appears on the screen, checking O and 0 as well as I and 1.`
                },
            }
        ],
        [
            {
                type: 'text',
                label: `RAW ID (RID)`,
                placeholder: 'RAW ID (RID)',
                hidden: !usesRawId(formValues.product_id),
                key: 'raw_id',
                tooltip: {
                    label: `How do I obtain this?`,
                    copy: `You can obtain your RAW ID by entering the Activation menu on your device, pressing the Go To RAW button and connecting a compatible camera capable of outputting a RAW signal to your device via HDMI or SDI.`
                },
                readOnly: !!isAddAid
            }
        ],
        [
            {
                type: 'date',
                label: `Date of purchase`,
                key: 'date_of_purchase',
                readOnly: !!isAddAid
            },
            {
                type: 'text',
                label: `Enter your reseller`,
                key: 'dealer_name',
                placeholder: 'Enter your Reseller',
                readOnly: !!isAddAid
            }
        ],
        [
            {
                type: 'button',
                label: 'SUBMIT',
                key: 'submit'
            }
        ],
    ]

    return (
        <FormContainer
            heading={heading}
            copy={copy}
            rows={rows}
            values={formValues}
            errors={errors}
            loading={loading}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            skipForNow={!!loggedIn}
            _skipForNow={_skipForNow}
        />

    )
}

export default RegisterForm