import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { isSupport, ToggleMenu } from 'utils'
import { getUserAccountData, getMenuToggle } from 'state/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { getUserById } from 'state/actions'
import { NavLink } from 'react-router-dom'
import {
	HelpCircle,
} from 'react-feather'

import logo from 'assets/images/myatomos-logo.png'

import UserButton from 'components/userButton'
import SupportNavbarDetails from 'components/support/supportNavbarDetails'

const SupportNavBar = ({ user }) => {
	const [hasMounted, setHasMounted] = useState(false)
	const doToggleMenu = ToggleMenu()
	const menuToggle = useSelector(getMenuToggle)

	const dispatch = useDispatch()

	const isMenuActive = menuToggle.data ? menuToggle.data.active : false

    const { userId } = useParams()

	useEffect(() => {
		setHasMounted(true)

        if (_isSupport) {
            dispatch(getUserById({ userId }))
        }
	}, [])

	const handleToggleMenu = () => {
		doToggleMenu({
			data: {
				active: isMenuActive ? false : true
			}
		})
	}

	const loggedInUser = useSelector(getUserAccountData)
	const _isSupport = loggedInUser && isSupport(loggedInUser)

	const userAccountData = useSelector(state => getUserAccountData(state, userId))

	return (
		<aside className={
            hasMounted ? (isMenuActive ? 'mounted active' : 'mounted') : ''
        }>
            <section className='top'>
                <NavLink exact to='/'>
                    <img src={logo} alt='Atomos' />
                </NavLink>

                <div className="singleUser-nav">
                    { userAccountData &&
                        <SupportNavbarDetails user={ userAccountData } />
                    }
                </div>

            </section>
            <section className='bottom'>
				<UserButton user={user} />
				<div onClick={handleToggleMenu}>
					<a href={`https://atomos.com/support`} target='_blank'>
						<HelpCircle size={20} />
						Support
					</a>
				</div>
			</section>
		</aside>
	)
}

export default SupportNavBar