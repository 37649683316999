import React from 'react'

export default function ImageCta({
    ctas,
    ctaLoading,
    isFirst,
}) {
    const innerJsx = ctas ? ctas.map((cta, index) => {
        if(!cta || !cta.button){
            return null 
        }

        return (
            <div key={index} className="imageCta">
                <a
                    href={cta.button.url ? 'https://atomos.com' + cta.button.url : undefined}
                    onClick={cta.button.onClick}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="container">
                        <div className="imageCta-image-cont">
                            <div className="imageCta-image" style={{ backgroundImage: cta.image ? `url(${cta.image})` : '' }} />
                        </div>
                        <div className="imageCta-text">
                            <h3 dangerouslySetInnerHTML={{ __html: cta.title }} />
                            {cta.body &&
                                <div className={'copy'} dangerouslySetInnerHTML={{ __html: cta.body }} />
                            }
                            <div className="button" dangerouslySetInnerHTML={{ __html: cta.button.title }} />
                        </div>
                    </div>
                </a>
            </div>
        )
    }) : null

    return (
        ctaLoading ?
            <div className="imageCta loading">
                <ul className={'loading-lines'}>
                    <li />
                    <li />
                    <li />
                </ul>
            </div>
            :
            innerJsx ?
                !isFirst ?
                    <div>
                        {innerJsx}
                    </div>
                    : innerJsx
                : (
                    <div className="imageCta" />
                )
    )
}
