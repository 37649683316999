import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { checkConnectPromo, connectPromo } from 'state/actions'
import { getCurrentUser } from 'state/selectors'
import { useSelector } from 'react-redux'

import Button from 'components/button'

import { X } from 'react-feather'
import { cloneDeep } from 'lodash'

import countryList from 'react-select-country-list'
import FormContainer from 'components/forms/formContainer'

export default function ConnectPromo({ type, data }) {
    const {
        promoType,
        heading,
        copy,
    } = data

    const countryOptions = useMemo(() => countryList().getLabels(), [])

    const _countryOptions = countryOptions.map(country => {
        return {
            label: country,
            value: country
        }
    })

    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: '',
        })
    }

    const [formValues, setFormValues] = useState({})
    const [errors, setErrors] = useState([{}, {}])
    const [loading, setLoading] = useState(false)
    const [alreadyRegistered, setAlreadyRegistered] = useState(false)
    const [success, setSuccess] = useState(false)

    const { id: userId } = useSelector(getCurrentUser)

    useEffect(() => {
        setLoading(true)
        checkConnectPromo(userId, promoType).then(({ data }) => {
            if (data === 'found') {
                setAlreadyRegistered(true)

            }
            setLoading(false)
        })
    }, [])

    const handleSubmit = () => {
        const {
            preOrderedConfirmed,
            resellerName,
            resellerCountry,
        } = formValues

        if (!preOrderedConfirmed) {
            return handleError(null, `Please confirm you have pre-ordered a ${heading}.`)
        }

        if (!resellerName) {
            return handleError(null, `Please enter a Reseller.`)
        }

        if (!resellerCountry) {
            return handleError(null, `Please enter a Reseller country.`)
        }

        setLoading(true)

        connectPromo(userId, resellerName, resellerCountry, promoType).then(({ data }) => {
            return setSuccess(true)
        }).catch(error => {
            return handleError(null, `Oops, something went wrong.`)
        })

        setTimeout(() => {
            setLoading(false)
        }, 1000)

        return false
    }

    const handleError = (key, value) => {
        const _errors = cloneDeep(errors)
        _errors[key] = value

        setErrors(_errors)
    }

    const handleChange = (key, value) => {

        const _formValues = { ...formValues }

        _formValues[key] = value

        setFormValues(_formValues)
    }

    const _copy = alreadyRegistered ?
        `You are already registered for ${promoType} connect. Please check your email for further instructions.`
        : copy

    const preOrderName = (() => {
        switch (promoType) {
            case 'Atomos':
                return 'an Atomos CONNECT'
            case 'Shogun':
                return 'a Shogun CONNECT'
            case 'ZATO':
                return 'a ZATO CONNECT'
        }
    })()

    let rows = [
        [
            {
                type: 'checkbox',
                text: `I confirm I pre-ordered ${preOrderName}.`,
                key: 'preOrderedConfirmed'
            }
        ],
        [
            {
                type: 'text',
                key: 'resellerName',
                placeholder: `Reseller name`,
            },
        ],
        [
            {
                type: 'select',
                label: 'Country of reseller',
                key: 'resellerCountry',
                options: _countryOptions,
                placeholder: 'Search countries...'
            }
        ],
        [
            {
                type: 'button',
                label: 'CLAIM NOW',
                key: 'submit',
            },
        ],
    ]

    if (alreadyRegistered) rows = []

    return (
        <div className={type} onClick={(e) => e.stopPropagation()}>
            <X className='modal-close' onClick={closeModal} />
            <div>
                {success ?
                    <div className="success">
                        <h2>{`Success! You are registered for ${promoType} connect`}</h2>
                        <p className="copy large">
                            Check your email for further instructions.
                        </p>
                        <Button
                            label={"Close"}
                            full
                            onClick={closeModal}
                        />
                    </div>
                    :
                    <div className="button-row">
                        <FormContainer
                            heading={heading}
                            copy={_copy}
                            rows={rows}
                            errors={errors}
                            values={formValues}
                            loading={loading}
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                        />
                    </div>
                }
            </div>
        </div>
    )
}
