import React, { useEffect } from 'react'
import { useMainContext } from 'utils'

import AcademyHero from 'components/academyHero'
import AcademyCategories from 'components/academyCategories'
import loadingImage from 'assets/images/loading.svg'

import { getAcademyPostsByCat } from 'state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getAcademyCategories } from 'state/selectors'

const Academy = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getAcademyPostsByCat())
	}, [])

	const filteredPosts = useSelector(getAcademyCategories)

	const { setIsHeaderTransparent } = useMainContext()

	useEffect(() => {
		setIsHeaderTransparent(true)
	}, [])

	return (
		<div
			className={
				filteredPosts.length > 0
					? 'academy-section'
					: 'academy-section loading'
			}
		>
			<img src={loadingImage} className={`loader`} alt='loader' />
			<AcademyHero />
			<section>
				{filteredPosts.map((categories, index) => {
					const { name, slug, posts, description } = categories

					return (
						<AcademyCategories
							title={name}
							filteredPosts={posts}
							key={index}
							slug={slug}
							description={description}
						/>
					)
				})}
			</section>
		</div>
	)
}

export default Academy