import React, { useState } from 'react'

import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js'

import Button from 'components/button'
import CardDisplay from 'components/cardDisplay'

const CardForm = ({
    buttonLabel,
    currentUser,
    handleSubmit,
    setLoading,
    couponCode,
    handleCouponChange,
    couponResponse,
    isCouponDataLoading
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const [useExistingCard, setUseExistingCard] = useState(!!currentUser.stripe_id)

    const [saveCard, setSaveCard] = useState(true)

    const _handleSubmit = async e => {
        if (isCouponDataLoading) {
            return
        }

        if (e) {
            e.preventDefault()
        }

        setLoading(true)

        const response = useExistingCard ? {
            token: null,
            error: null
        } : await stripe.createToken(elements.getElement(CardElement), {
            currency: "usd",
            name: `${currentUser.first_name} ${currentUser.last_name}`,
        })

        const {
            token,
            error
        } = response

        if (error) {
            return handleSubmit({
                type: 'FAILED',
                data: error
            })
        }

        return handleSubmit({
            type: 'SUCCESS',
            data: {
                token,
                addingNewPaymentMethod: !useExistingCard,
                saveCard
            }
        })
    }

    const tabOptions = ['Existing Card', 'New card']

    return (
        <form onSubmit={_handleSubmit}>
            {(currentUser && currentUser.stripe_id) &&
                <ul className={'existing-card-options'}>
                    {tabOptions.map((option, index) => (
                        <li key={option}>
                            <label className={useExistingCard === !index ? 'selected' : ''}>
                                <input
                                    type="radio"
                                    name={'useExistingCard'}
                                    checked={useExistingCard === !index}
                                    onChange={() => setUseExistingCard(!index)}
                                />
                                <span>
                                    {option}
                                </span>
                            </label>
                        </li>
                    ))}
                </ul>
            }
            {useExistingCard ?
                <CardDisplay user={currentUser} />
                :
                <CardElement />
            }
            <div className="button-container">
                {handleCouponChange ?
                    <input
                        type={'text'}
                        placeholder={'Have a coupon code?'}
                        value={couponCode}
                        onChange={handleCouponChange}
                    />
                    : null}
                <Button
                    label={buttonLabel}
                    inverted
                    onClick={_handleSubmit}
                    disabled={isCouponDataLoading}
                />
            </div>
            {!useExistingCard ?
                <div className={'save-details'}>
                    <label>
                        <input
                            type='checkbox'
                            checked={saveCard}
                            onChange={() => setSaveCard(!saveCard)}
                        />
                        <span>
                            Save this card for future purchases
                        </span>
                    </label>
                </div>
                : null}
            {couponResponse &&
                <div className={`coupon-response ${couponResponse.type}`}>
                    {couponResponse.text}
                </div>
            }
        </form>
    )
}

export default CardForm