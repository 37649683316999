import { isDev, isStage } from "utils";

const allowedTimeDifferenceSeconds = 60 * 60 * 24 * 30 // request should have been made in the last 30 days

// !!! these functions are for debugging only and do not validate user input !!!

// signing is HmacSHA512("device-type=shogunConnect&serial=abc123&ts=unixseconds", "secret")
// When signing, the param order is important, alphabetical order

export const generateUrl = (registration) => {
    const isQa = isDev || isStage

    const { product_display_name, product_name } = registration.product

    const pascalCaseProductName = (() => {
        const _name = product_display_name || product_name

        const segments = _name.split(' ')

        let response = ''

        segments.map((segment, index) => {
            if (index < 2) {
                response += segment
            }
        })

        return response
    })()

    const key = isQa ? `hGZIRqKcNaTVP7zuVedDSKHtKdsnOpd7!Ce3i5%GVLN%z^Po1A%qyfm49hqW1C189gyQa$!&wrsXZ*GalKb^^ZNZ7MKNBXeKUWVLppiBKm2WA@H5v%R4#M%n#s@Eqz^U` : `ukvw43vx*aGuOCN$&Ue6QRRDhYs%AMessdQ*BXTCtGX!s947reGdcreL*F53C&3WqiAH56HfjV^IwaVL$wHfGvMfra&RykI!Mvexe6cWLK!eGhtWSO#@&c@&6b$%lPG5`

    return signUrl(`https://${isQa ? 'qa.' : ''}atomos.cloud/account/subscription/free-trial`, pascalCaseProductName, registration.serial_number, key)
}

// utility function for signing requests
async function sign(secret, body) {
    const encoder = new TextEncoder("utf-8");
    const algorithm = { name: "HMAC", hash: "SHA-512" };

    const key = await crypto.subtle.importKey("raw", encoder.encode(secret), algorithm, false, ["sign", "verify"]);
    const signature = await crypto.subtle.sign(algorithm.name, key, encoder.encode(body));

    // convert buffer to byte array
    const hashArray = Array.from(new Uint8Array(signature));

    // convert bytes to hex string
    const digest = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

    return digest
}

// generate a url
export async function signUrl(baseUrl, deviceType, serial, secret) {
    // Build up the url
    const url = new URL(baseUrl)

    // params are added in alphabetical order

    // device types help define what length and type of free trial
    url.searchParams.append("device-type", deviceType)

    // we keep track of serials so trials can only be started once
    url.searchParams.append("serial", serial)

    // we use the time in the url to make sure requests are only valid for a short period of time
    const now = Math.floor(Date.now() / 1000) // unix seconds utc
    url.searchParams.append("ts", now)

    // create a signature based on the url params
    const signature = await sign(secret, url.searchParams.toString())

    // now add the signature to the url
    url.searchParams.append("signature", signature)

    return url.toString()
}


// check if a url is valid
export async function isUrlValid(incomingUrl, secret) {
    const url = new URL(incomingUrl)

    // check the time is within our allowed range
    const now = Math.floor(Date.now() / 1000) // unix seconds utc
    const incomingTimestamp = url.searchParams.get("ts")

    if (incomingTimestamp > now + allowedTimeDifferenceSeconds || incomingTimestamp < now - allowedTimeDifferenceSeconds) {
        return false
    }

    // read the signature	
    const incomingSignature = url.searchParams.get("signature")

    // now make a new set of params to generate a signature to compare
    // and ensure the params are in the right order
    const params = new URLSearchParams()
    params.append("device-type", url.searchParams.get("device-type"))
    params.append("serial", url.searchParams.get("serial"))
    params.append("ts", url.searchParams.get("ts"))
    const signature = await sign(secret, params.toString())

    // the signatures should match
    if (signature !== incomingSignature) {
        return false
    }

    return true
}