import React from 'react'

import Tabs from 'components/singleRegistration/tabs'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useRouteMatch } from 'react-router'

import SearchInner from './searchInner'

const Search = ({

}) => {
    const match = useRouteMatch()

    const tabs = [
		{
			label: 'Search by customer',
			value: 'customers',
            copy: `Find customers by searching their email, or first/last name.`,
			Component: SearchInner
		},
		{
			label: 'Search by device',
			value: 'products',
            copy: `Find devices by searching their ID, serial number, DID or AID.`,
			Component: SearchInner
		},
	]

    return (
        <div className={'support-search'}>
            <section className="heading">
                <h2>
                    Welcome to MyAtomos support.
                </h2>
            </section>
            <Tabs
                tabs={tabs}
                routeSlug={'/support'}
            />
            <div className={`support-sections`}>
                <Switch>
                    {tabs.map(({
                        value,
                        Component,
                        copy,
                    }) => (
                        <Route
                            key={value}
                            path={
                                match.url + '/' + value
                            }
                            render={params => (
                                <div
                                    className={'inner'}
                                >
                                    <div className='support-title'>
                                        <div className={'title-inner'}>
                                            <div
                                                className={'copy'}
                                                dangerouslySetInnerHTML={{ __html: copy }}
                                            />
                                        </div>
                                    </div>
                                    <Component
                                        {...params}
                                        type={value}
                                    />
                                </div>
                            )}
                        />
                    ))}
                    <Redirect
                        to={`${match.url}/${tabs[0].value}`}
                    /> 
                </Switch>
            </div>
        </div>
    )
}

export default Search