import { useEffect } from "react"
import { useMainContext, useModal } from "utils"

const usePlayProModal = userAccountData => {
    const {
        shouldShowPlayProPromo,
        hasShownPlayProModal
    } = useMainContext()

    const doModal = useModal()

    useEffect(() => {
        if (shouldShowPlayProPromo && userAccountData && !userAccountData.playprokey && !hasShownPlayProModal.current) {
            hasShownPlayProModal.current = true

            doModal({
                type: 'PROMO'
            })
        }
    }, [userAccountData, hasShownPlayProModal])
}

export default usePlayProModal