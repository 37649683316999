import React from 'react'
import { ChevronLeft } from 'react-feather'

import HeaderLogo from 'assets/images/header-logo.png'

export default function authHeader() {
    return (
        <div className='auth-header container-auth'>
            <a className='auth-header-image' href='/'>
                <img src={HeaderLogo} alt="Atomos" />
            </a>
            <a href='https://www.atomos.com'>
                <ChevronLeft size={18} />
                BACK TO MAIN SITE
            </a>
        </div>
    )
}
