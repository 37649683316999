import React, { useState, useRef, useEffect } from 'react'
import { useModal, isSupport } from 'utils'

import FormContainer from 'components/forms/formContainer'

import {
	deleteRegistration,
} from 'state/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccountData, getCurrentUser } from 'state/selectors'

const DeleteRegistration = ({
	title,
	copy,
	isEdit,
	user,
}) => {
	const [formValues, setFormValues] = useState({})
	const formValuesRef = useRef(formValues)
	formValuesRef.current = formValues

	const [errors] = useState([{}, {}])
	const [loading, setLoading] = useState(false)
	const errorsRef = useRef(errors)
	errorsRef.current = errors

	const doModal = useModal()

	const dispatch = useDispatch()

	const currentUser = useSelector(getCurrentUser)
	const userAccountData = useSelector(getUserAccountData)

	const _isSupport = isSupport(userAccountData)

	useEffect(() => {
        setFormValues({ ...user })
	}, [user, currentUser])

	const handleSubmit = () => {
        if ( _isSupport ) {
            const idToDelete = formValues.id

            setLoading(true)

            dispatch(
                deleteRegistration(idToDelete, response => {
                    doModal({
                        type: null,
                        data: {}
                    })
                })
            )


        }
        
        return false
	}

	const DeleteRegistrationForm = [
		[
			{
				type: 'text',
				hidden: true,
				placeholder: 'id',
				key: 'id'
			}
		],
		[
			{
				type: 'button',
				label: 'Confirm',
				key: 'confirm'
			}
		]
	]

	const formContainersJsx = (
		<>
			<FormContainer
				isEdit={isEdit}
				heading={title}
				copy={copy}
				rows={DeleteRegistrationForm}
				values={formValues}
				errors={errors[0]}
				loading={loading}
				handleSubmit={handleSubmit}
			/>
		</>
	)

	return (
		<div className={`container-narrow`}>
			<div className={`form-wrap register`}>
				{formContainersJsx}
			</div>
		</div>
	)
}

export default DeleteRegistration