import React from 'react'
import { useDispatch } from 'react-redux'
import { removePaymentMethod } from 'state/actions'

import {
	useModal
} from 'utils'

const CardDisplay = ({ user }) => {
	const cardSlug = (() => {
		switch (user.card_brand) {
			case 'Visa':
				return 'visa'
			case 'MasterCard':
				return 'mastercard'
			case 'American Express':
				return 'amex'
		}

		return null
	})()

	const doModal = useModal()
	const dispatch = useDispatch()

	if (!cardSlug) {
		return null
	}

	return (
		<div className={'existing-card-display'}>
			{cardSlug && <img src={`/images/cards/${cardSlug}.png`} />}
			<span>
				&bull; &bull; &bull; &bull; &nbsp; &bull; &bull; &bull; &bull;
				&nbsp; &bull; &bull; &bull; &bull; &nbsp; {user.card_last_four}
			</span>
			<button
				className={`delete-card`}
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()

					doModal({
						type: null,
						data: null
					})

					setTimeout(() => {
						doModal({
							type: 'TEXT',
							data: {
								title: `Delete your payment method?`,
								copy: `This'll permanently remove your payment method.`,
								narrow: true,
								button: {
									onClick: () => {
										doModal({
											type: null,
											data: null
										})

										dispatch(removePaymentMethod())

										setTimeout(() => {
											doModal({
												type: 'TEXT',
												data: {
													title: `Payment details removed`,
													copy: `We've deleted your payment method entirely from our systems.`,
												},
											})
										}, 100)
									},
									label: 'Yes',
									isInverted: true
								},
								buttonLow: {
									onClick: () => {
										doModal({
											type: null,
											data: null
										})
									},
									label: 'Cancel',
								}
							}
						})
					}, 100)
				}}
			>
				<i className='fa fa-trash-o' />
			</button>
		</div>
	)
}

export default CardDisplay