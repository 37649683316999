import React, { useRef, useEffect } from 'react'
import { PlusCircle } from 'react-feather'
import { useSelector } from 'react-redux'
import { getUserAccountData } from 'state/selectors'
import { useModal, useMainContext } from 'utils'

import Button from 'components/button'
import Slider from 'components/slider'
import ProductTile from 'components/productTile'

export default function MyProductsSlider() {
    const userAccountData = useSelector(getUserAccountData)
    const registrations = userAccountData.registrations
    const registrationsRef = useRef(registrations)

    const { isMobile } = useMainContext()

    const sliderRef = useRef(null)

    const doModal = useModal()

    const openRegisterModal = () => {
        doModal({
            type: 'REGISTER_PRODUCTS',
            data: {
                title: 'Register a product',
                copy: `Edit your details here.`,
            },
        })
    }

    useEffect(() => {
        if (sliderRef.current) {
            if (!registrationsRef.current || registrationsRef.current.length !== registrations.length) {
                sliderRef.current.reloadCells()

                registrationsRef.current = registrations
            }

            sliderRef.current.on('dragStart', () => {
                sliderRef.current.slider.style.pointerEvents = 'none'
            })

            sliderRef.current.on('dragEnd', () => {
                sliderRef.current.slider.style.pointerEvents = 'all'
            })
        }
    }, [registrations])

    useEffect(() => {
        setTimeout(() => {
            if (sliderRef.current) {
                sliderRef.current.resize()
            }
        }, 1000)
    }, [])

    return (
        <>
            <div className="myProductsSlider">
                <Slider
                    className={'myProductsSlider-slideshow'}
                    options={{
                        draggable: isMobile ? true : (registrations && registrations.length) > 3 ? true : false,
                        prevNextButtons: false,
                        pageDots: (registrations && registrations.length) > 3 ? true : false,
                        cellAlign: 'left',
                        contain: true,
                        wrapAround: true,
                        groupCells: 3
                    }}
                    flickityRef={ref => sliderRef.current = ref}
                >
                    {registrations && registrations.map((registration, index) => {
                        if (!registration.product) {
                            return null
                        }

                        return (
                            <div key={index} className="myProductsSlider-productTile">
                                <ProductTile registration={registration} />
                            </div>
                        )
                    })}
                    <div className="myProductsSlider-register-cont">
                        <div className="myProductsSlider-register">
                            <a onClick={openRegisterModal}>
                                <PlusCircle size={80} strokeWidth={0.5} />
                                <h5 dangerouslySetInnerHTML={{ __html: 'Register Product' }} />
                            </a>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="myProductsSlider-mobileButton">
                <Button onClick={openRegisterModal} label="Register Product" inverted register />
            </div>
        </>
    )
}
