import React, { useEffect, useState } from 'react'
import DashboardHero from 'components/dashboard/hero'
import MyProductsSlider from 'components/myProductsSlider'
import ImageCta from 'components/imageCta'
import DashboardAcademyList from 'components/academyList'
import { ArrowRight } from 'react-feather'

import { usePlayProContent } from 'utils'
import usePlayProModal from 'utils/usePlayProModal'

import {
    getDashboardCtas,
    getDashboardHeroPosts
} from 'state/actions'

import { useDispatch, useSelector } from 'react-redux'
import {
    getAllPosts,
    getHeroPosts,
    getCtas,
    getUserAccountData
} from 'state/selectors'

import {
    useMainContext,
} from 'utils'

import { Link } from 'react-router-dom'

export default function Dashboard() {
    const [hasMounted, setHasMounted] = useState(false)
    const [ctaLoading, setCtaLoading] = useState(true)

    useEffect(() => {
        setHasMounted(true)
    }, [])

    const allPosts = useSelector(getAllPosts)
    const userAccountData = useSelector(getUserAccountData)
    const heroPosts = useSelector(getHeroPosts)

    const {
        setIsHeaderTransparent,
        shouldShowPlayProPromo
    } = useMainContext()

    useEffect(() => {
        setIsHeaderTransparent(true)
    }, [])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDashboardCtas())
        dispatch(getDashboardHeroPosts())
    }, [])
    
    usePlayProModal(userAccountData)

    const Ctas = useSelector(getCtas)

    const playProContent = usePlayProContent()

    useEffect(() => {
        if (Ctas.length) {
            setCtaLoading(false)
        }
    }, [Ctas])

    const Cta1 = (() => {
        if(shouldShowPlayProPromo){
            return playProContent
        }
        
        if (Ctas.length > 0) return Ctas[0]
        return null
    })()

    const Cta2 = (() => {
        if ((Ctas.length > 1)) return Ctas[1]
        return null
    })()

    const CtaWidget1 = Cta1 ? {
        key: 'imageCta',
        Component: ImageCta,
        props: {
            ctas: [Cta1],
            ctaLoading,
            isFirst: true
        }
    } : null

    const CtaWidget2 = {
        key: 'imageCta2',
        Component: ImageCta,
        props: {
            ctas: [Cta2],
            ctaLoading
        }
    }

    const widgets = [
        {
            title: 'My Atomos Devices',
            key: 'myProducts',
            Component: MyProductsSlider
        },
        CtaWidget1,
        {
            title: 'Latest from Academy',
            link: '/academy',
            key: 'academyList',
            Component: DashboardAcademyList,
            props: {
                allPosts
            }
        },
        CtaWidget2,
    ]

    return (
        <div className={`dashboard no-padding-top ${hasMounted ? 'mounted' : ''}`}>
            <DashboardHero type='dashboard' allPosts={heroPosts} />
            <div className="container">
                <div className="dashboard-main widget-container">
                    {widgets.map(widget => {
                        if (!widget) {
                            return null
                        }

                        const {
                            title,
                            key,
                            link,
                            Component,
                            props
                        } = widget

                        return (
                            <div className={`dashboard-${key}`} key={key}>
                                {title &&
                                    <h3>
                                        {title}
                                        {link &&
                                            <Link to={link}>
                                                View all
                                                <ArrowRight size={16} />
                                            </Link>
                                        }
                                    </h3>
                                }
                                <Component {...props} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
