import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useContext
} from 'react'
import Slider from 'components/slider'
import AcademyPost from 'components/academyPost'
import ProgressSlider from 'components/rangeSlider'
import { MainContext } from 'utils/context'

import { setSliderScrollListener } from 'utils'

export default function AcademyCategories({
	slug,
	title,
	filteredPosts,
	description
}) {
	const [handlePosition, setHandlePosition] = useState(0)
	const slidesRef = useRef([])
	const sliderRef = useRef(null)
	const carouselContainerRef = useRef(null)
	const childrenWidth = useRef(0)
	const handlePositionRef = useRef(handlePosition)
	const flickityRef = useRef(null)

	handlePositionRef.current = handlePosition

	const { isMobile } = useContext(MainContext)

	const handleChange = useCallback((value, skipTransform) => {
		if (!skipTransform) {
			const maxScroll = getMaxScrollValue()

			const sliderElement = getSliderElement()

			const newTranslationValue = maxScroll * (value / 100)

			sliderElement.style.transform = `translateX(-${newTranslationValue}%)`
			flickityRef.current.x = newTranslationValue * -1
		}

		setHandlePosition(value)
	}, [])

	useEffect(() => {
		setTimeout(() => {
			let _childrenWidth = 0
			if (slidesRef.current.length) {
				slidesRef.current.forEach(slide => {
					const width = slide.getBoundingClientRect().width

					_childrenWidth += width
				})
			}

			childrenWidth.current = _childrenWidth
		}, 100)
	}, [handleChange])

	useEffect(() => {
		setSliderScrollListener(flickityRef, handleChange)
	}, [handleChange])

	const getSliderElement = _ => {
		const sliderElement = sliderRef.current.getElementsByClassName(
			'flickity-slider'
		)[0]
		// const sliderElement = sliderRef.current.selectedCell.element

		return sliderElement
	}

	const getMaxScrollValue = _ => {
		let sliderWidth = ''
		let widthDeficit = ''
		let maxScroll = ''

		if (sliderRef.current) {
			sliderWidth = sliderRef.current.getBoundingClientRect().width
			widthDeficit = childrenWidth.current - sliderWidth
			maxScroll = (widthDeficit / sliderWidth) * 100
		}

		return maxScroll
	}

	let middle = false
	let sliderSide = 'left'
	if (title === 'Inspire') {
		middle = true
		sliderSide = 'right'
	}

	const _filteredPosts = filteredPosts.filter(post => post.hidePage !== true)

	return (
		<div className={`academy-category`}>
			<section className='academy-category__content'>
				<div
					className='h3'
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				<div
					className='copy large'
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			</section>
			<section
				className='academy-category__slider-section'
				ref={ref => (carouselContainerRef.current = ref)}
			>
				<Slider
					className={'academy-category__slideshow'}
					options={{
						fade: false,
						prevNextButtons: false,
						pageDots: false,
						cellAlign: isMobile ? 'left' : sliderSide,
						rightToLeft: isMobile ? false : middle,
						contain: true,
						wrapAround: false,
						draggable: true,
						hash: true
					}}
					flickityRef={ref => (sliderRef.current = ref)}
				>
					{_filteredPosts &&
						_filteredPosts.map((item, index) => {
							const { title, link, imageUrl, categories } = item

							return (
								<div className='academyPost-cont' key={index}>
									<AcademyPost
										categoryPost
										title={title}
										link={link}
										categories={categories}
										image={imageUrl}
									/>
								</div>
							)
						})}
					<li
						style={{ listStyleType: 'none' }}
						className={'buffer'}
						ref={ref => (slidesRef.current[4] = ref)}
					>
						<div className={'inner'} />
					</li>
				</Slider>
				<ProgressSlider
				// onChange={handleChange}
				// onChangeStart={(_) => _handleChangeStart(true)}
				// onChangeComplete={(_) => _handleChangeStart(false)}
				// value={handlePosition}
				/>
			</section>
		</div>
	)
}
