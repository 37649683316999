import React, { useContext } from 'react'
import { MainContext } from 'utils/context'

import Button from 'components/button'
import AcademyPost from 'components/academyPost'

export default function AcademyList({ allPosts }) {
	const { isMobile } = useContext(MainContext)

	const _allPosts = allPosts ? isMobile ? allPosts.filter(Boolean).reverse().filter(post => post.hidePage !== true).slice(0, 2) : allPosts.filter(Boolean).reverse().filter(post => post.hidePage !== true).slice(0, 5) : []

	return (
		<>
			<div className='academyList'>
				{_allPosts.map((post, index) => {
					const { title, link, imageUrl, categories, content } = post

					return (
						<AcademyPost
							key={index}
							title={title}
							link={link}
							image={imageUrl}
							excerpt={content}
							categories={categories}
						/>
					)
				})}
			</div>
			<div className="academyList-mobileButton">
				<Button url="https://atomos.com/academy/" label="View more articles" inverted />
			</div>
		</>
	)
}
