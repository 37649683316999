import React from 'react'
import AcademySlider from 'components/academySlider'

export default function DashboardHero({ type, allPosts }) {
    return (
        <div className='dashboard-hero-cont'>
            <div className='dashboard-hero'>
                {type === 'dashboard' ? 
                    <AcademySlider allPosts={allPosts} />
                    :
                    null
                }
            </div>
        </div>
    )
}
