import React, { useEffect, useRef } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { spring, AnimatedSwitch } from 'react-router-transition'
import { useSelector } from 'react-redux'
import { getUserAccountData, getCurrentUser } from 'state/selectors'

import Modal from 'components/modal'
import Navbar from 'components/navbar'
import Header from 'components/header'
import Dashboard from 'components/dashboard'
import MyProducts from 'components/myProducts'
import SingleRegistration from 'components/singleRegistration'
import Academy from 'components/academy'
import SupportSearch from 'components/support/search'
import SingleUser from 'components/support/singleUser'

import PageLoader from 'components/pageLoader'

import { useDispatch } from 'react-redux'
import { getUserByToken, getAcademyPosts, dismissPreferencesPrompt } from 'state/actions'
import axios from 'axios'
import {
	useMainContext,
	useModal,
	supportUserCan,
} from 'utils'

import 'sass/main.sass'

const Main = () => {
	const dispatch = useDispatch()
	const doModal = useModal()
	const hasCheckedForPreferences = useRef(false)

	const mapStyles = styles => {
		return {
			opacity: styles.opacity,
			transform: `translateY(${styles.translateY}px)`,
		}
	}

	const {
		logOut
	} = useMainContext()

	const bounce = val => {
		return spring(val, {
			stiffness: 330,
			damping: 55,
		})
	}

	const bounceTransition = {
		atEnter: {
			opacity: 0,
			translateY: 24,
		},
		atLeave: {
			opacity: bounce(0),
			translateY: bounce(-24),
		},
		atActive: {
			opacity: bounce(1),
			translateY: bounce(0),
		},
	}

	const userAccountData = useSelector(getUserAccountData)
	const currentUser = useSelector(getCurrentUser)

	const userAccountDataRef = useRef(userAccountData)
	userAccountDataRef.current = userAccountData

	useEffect(() => {
		const token = window.localStorage.getItem('token')

		dispatch(getAcademyPosts())

		if (token) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

			dispatch(
				getUserByToken((callback) => {
					const { type } = callback

					if (type === 'FAILED') {
						logOut()
					}

					if (window.urlParams) {
						console.log(window.urlParams)
						if (window.urlParams.type) {
							if (userAccountDataRef.current && window.urlParams.rid && window.urlParams.aid) {
								const { registrations } = userAccountDataRef.current

								const registrationExists = registrations.find(registration => registration.aid === window.urlParams.aid)

								if (registrationExists) {
									return doModal({
										type: 'ADD_RAW_ID',
										data: {
											rawId: window.urlParams.rid,
											registration: registrationExists
										},
									})
								}
							}

							doModal({
								type: 'REGISTER_PRODUCTS',
								data: {
									title: 'Complete your registration',
									copy: `Enter the remaining device details below, and we'll finish up.`,
								},
							})
						} else if (window.urlParams.errorModal) {
							doModal({
								type: 'TEXT',
								data: {
									title: `Allocation exhausted`,
									copy: `Sorry about this. Our Dropbox promotion has exhausted its allocation.`,
									narrow: true,
									button: {
										onClick: () => {
											doModal({
												type: null,
												data: null
											})
										},
										label: 'Okay',
										isInverted: true
									},
								}
							})
						}
					}
				})
			)
		} else {
			logOut()
		}
	}, [])

	useEffect(() => {
		if (currentUser && !hasCheckedForPreferences.current) {
			hasCheckedForPreferences.current = true

			if (!currentUser.preferred_camera && !currentUser.preferred_edit_software && !currentUser.user_type && !currentUser.has_prompted_for_preferences) {
				doModal({
					type: 'TEXT',
					data: {
						title: `Enter your preferences, ${currentUser.first_name}?`,
						copy: `Take a second to tell us how you use Atomos. It'll help us personalise your MyAtomos experience.`,
						narrow: true,
						button: {
							onClick: () => {
								doModal({
									type: null,
									data: null
								})

								setTimeout(() => {
									doModal({
										type: 'EDIT_PROFILE',
										data: {
											title: `Update details`,
											copy: `Enter your details and interests here.`,
										},
									})
								}, 100)
							},
							label: 'Sounds good',
							isInverted: true
						},
						buttonLow: {
							onClick: () => {
								dispatch(dismissPreferencesPrompt(currentUser))

								doModal({
									type: null,
									data: null
								})
							},
							label: 'Not right now.',
						}
					}
				})
			}
		}
	}, [currentUser])

	const _isSupport = userAccountData && supportUserCan(userAccountData, 'SEARCH')

	const routes = _isSupport ? [
		{
			path: '/support',
			Component: SupportSearch
		},
		{
			path: '/customers/:userId?/:registrationId?',
			Component: SingleUser
		},
	] : [
		{
			path: '/',
			Component: Dashboard,
			isExact: true,
		},
		{
			path: '/my-products',
			Component: MyProducts,
			isExact: true,
		},
		{
			path: '/academy',
			Component: Academy,
			isExact: true,
		},
		{
			path: '/my-products/:registrationId',
			Component: SingleRegistration,
		},
	]

	return (
		<div className={`main-container ${_isSupport ? 'support' : ''}`}>
			<PageLoader visible={!userAccountData} />
			<Header user={userAccountData} />
			{userAccountData && (
				<>
					{!_isSupport &&
						<Navbar user={userAccountData} logOut={logOut} />
					}
					<main>
						<AnimatedSwitch
							atEnter={bounceTransition.atEnter}
							atLeave={bounceTransition.atLeave}
							atActive={bounceTransition.atActive}
							mapStyles={mapStyles}
							className='switch-wrapper'
						>
							{routes.map((route, index) => {
								const { path, Component, isExact } = route

								return (
									<Route
										key={index}
										path={path}
										exact={!!isExact}
										render={(params) => {
											if (path === '/') {
												return (
													<Redirect
														to={'/my-products'}
													/>
												)
											}
											return (
												<Component
													{...params}
												/>
											)
										}}
									/>
								)
							})}
							<Redirect to={routes[0].path} />
						</AnimatedSwitch>
					</main>
				</>
			)}
			<Modal />
		</div>
	)
}

export default Main