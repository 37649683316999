import React from 'react'

import Button from 'components/button'

export default function AcademyPost({
	largeHeading,
	title,
	image,
	categories,
	link,
	excerpt,
	categoryPost,
	isLoading
}) {
	const titleJsx = (
		<a
			href={`https://atomos.com${link}`}
			target='_blank'
			rel='noreferrer'
			dangerouslySetInnerHTML={{ __html: title }}
		/>
	)

	return (
		<div
			className={`academyPost ${categoryPost ? 'is-category' : ''} ${
				isLoading ? 'loading' : ''
			}`}
		>
			<div className='academyPost-image-cont'>
				<a
					href={`https://atomos.com${link}`}
					target='_blank'
					rel='noreferrer'
					className='academyPost-image'
					style={{ backgroundImage: `url(${image})` }}
				/>
			</div>
			<div className='academyPost-details'>
				<div className='academyPost-cats'>
					{categories &&
						categories.map((cat, index) => {
							if ( cat.slug === 'uncategorised' ) return false

							return (
								<h5
									className={`categoryLink ${cat.slug}`}
									key={index}
								>
									<a
										href={`https://atomos.com/academy/categories/${cat.slug}`}
										target='_blank'
										rel='noreferrer'
									>
										{cat.name}
									</a>
								</h5>
							)
						})}
				</div>
				{largeHeading ? <h2>{titleJsx}</h2> : <h3>{titleJsx}</h3>}
				<div className='academyPost-excerpt'>
					{isLoading ? (
						<ul className={'loading-lines'}>
							<li />
							<li />
							<li />
							<li />
						</ul>
					) : (
						<span
							dangerouslySetInnerHTML={{
								__html: excerpt ? excerpt + '...' : ''
							}}
						/>
					)}
				</div>
				<Button
					label='Learn more'
					url={`https://atomos.com${link}`}
					naked
				/>
			</div>
		</div>
	)
}
