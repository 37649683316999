import React, { useEffect } from 'react'
import {
	BrowserRouter as Router,
} from "react-router-dom"

import { Provider } from 'react-redux'
import store from 'state/store'
import TagManager from 'react-gtm-module'

import axios from 'axios'
import { isDev, isStage } from 'utils'

import AppInner from 'components/appInner'

import {
	Elements,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const tagManagerArgs = {
	gtmId: 'GTM-5TQS8ZJ'
}

TagManager.initialize(tagManagerArgs)

const stripePromise = loadStripe((isDev || isStage) ? 'pk_test_eZXIICrj3Tp3Y854s9qDmT6N' : 'pk_live_SmzEJaY9XaQazBOBtIBVmei9')

const App = () => {
	useEffect(() => {
		const tokenIfExists = window.localStorage.getItem('token')

		if (tokenIfExists) {
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenIfExists
		}

	}, [])

	return (
		<Provider store={store}>
			<Elements stripe={stripePromise}>
				<Router>
					<AppInner />
				</Router>
			</Elements>
		</Provider>
	)
}

export default App