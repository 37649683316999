import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useModal, filterSoftDeletes, supportUserCan } from 'utils'
import { useSelector } from 'react-redux'
import { getUserAccountData } from 'state/selectors'

import Button from 'components/button'

const SearchTable = ({
    columns,
    rows,
    getter,
    urlGetter
}) => {
	const userAccountData = useSelector(getUserAccountData)

    const canEdit = supportUserCan(userAccountData, 'EDIT_DETAILS')

	const doModal = useModal()

    const openProfileModal = currentUser => {
		doModal({
			type: 'EDIT_PROFILE',
			data: {
				title: 'Account details',
				copy: `Edit your details here.`,
                user: currentUser,
			},
		})
	}

    const openDeleteModal = (currentUser, type) => {

        const userName = ( currentUser.first_name || currentUser.last_name ) ? ' (' + currentUser.first_name + ' ' + currentUser.last_name + ')' : ''

        const modalType = type === 'user' ? 'DELETE_USER' : 'DELETE_REGISTRATION'
        const modalTitle = type === 'user' ? 'Delete User' : 'Delete Registration'
        const modalCopy = type === 'user' ? `Please confirm deletion for user - ${currentUser.id}${userName}` : `Please confirm deletion for Serial number - ${currentUser.serial_number}`

		doModal({
			type: modalType,
			data: {
				title: modalTitle,
				copy: modalCopy,
                user: currentUser,
			},
		})
	}

    const openReassignModal = item => {
        doModal({
			type: 'REASSIGN_REGISTRATION',
			data: {
				title: `Reassign this ${ item.product.product_name }?`,
				copy: `Select a user to reassign this to.`,
                registration: item
			},
		})
    }

    const _rows = filterSoftDeletes(rows)

    return (
        <div className={ _rows.length === 1 ? `search-table oneResult` : `search-table` } data-results={ _rows.length }>
            <table>
                <thead>
                    <tr>
                        { columns.map(( column, index ) => {
                            if ( column === 'Edit details' || column === 'Edit registration' ) {
                                return (
                                    <th key={ index } />
                                )
                            } else {
                                return (
                                    <th dangerouslySetInnerHTML={{ __html: column }} key={ index } />
                                )
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    { _rows.map(( row, index ) => {
                        const currentItem = row

                        const url = urlGetter(row)

                        return (
                            <tr key={ index }>
                                { columns.map(( column, index ) => {
                                    if ( column === 'Edit details' && canEdit ) {
                                        return (
                                            <td key={ index }>
                                                <Button label="Edit details" onClick={ () => openProfileModal(currentItem) } edit compact />
                                                <Button label="Delete user" onClick={ () => openDeleteModal(currentItem, 'user') } edit compact />
                                            </td>
                                        )
                                    } else if ( column === 'Edit registration' && canEdit ) {
                                        return (
                                            <td key={ index }>
                                                <Button label="Reassign" onClick={ () => openReassignModal(currentItem) } edit compact />
                                                <Button label="Delete" onClick={ () => openDeleteModal(currentItem, 'registration') } edit compact />
                                            </td>
                                        )
                                    } else if ( column === 'Name' ) {
                                        return (
                                            <td key={ index }>
                                                <NavLink to={ '/customers/' + currentItem.id } dangerouslySetInnerHTML={{ __html: getter(row, column) }} />
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td key={ index } data-column={ column }>
                                                <Link to={url}>
                                                    <span dangerouslySetInnerHTML={{ __html: getter(row, column)}} />
                                                </Link>
                                            </td>
                                        )
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default SearchTable