import React, { useRef, useEffect } from 'react'
import Slider from 'components/slider'
import AcademyPost from 'components/academyPost'

export default function AcademySlider({
    allPosts
}) {
    const sliderRef = useRef(null)
    const sliderRef2 = useRef(null)

    useEffect(() => {
        sliderRef2.current.on('change', index => {
            sliderRef.current.select(index)
        })
    },[])

    const _allPosts = allPosts ? allPosts.filter(Boolean).filter(post => post.hidePage !== true) : []

    if(!_allPosts.length){
        _allPosts.push({
            imageUrl: null,
            title: 'Loading',
            categories: [],
            content: 'Loading',
            isLoading: true
        })
    }

    return (
        <div className='academySlider'>
            <div className="container">
                <Slider
                    className={'academySlider-slideshow-images'}
                    options={{
                        fade: true,
                        prevNextButtons: false,
                        pageDots: false,
                        cellAlign: 'left',
                        contain: true,
                        wrapAround: true,
                        draggable: false,
                    }}
                    flickityRef={ref => sliderRef.current = ref}
                >
                    { _allPosts.map(( post, index ) => {
                        const {
                            link,
                            imageUrl,
                            isLoading
                        } = post

                        return (
                            <AcademyPost 
                                key={index}
                                link={link}
                                image={imageUrl}
                                isLoading={isLoading}
                            />
                        )
                    })
                    }
                </Slider>
                <Slider
                    className={'academySlider-slideshow-content'}
                    options={{
                        prevNextButtons: false,
                        pageDots: true,
                        cellAlign: 'left',
                        contain: true,
                        wrapAround: true,
                        adaptiveHeight: true
                    }}
                    flickityRef={ref => sliderRef2.current = ref}
                >
                    { _allPosts.map(( post, index ) => {
                        const {
                            title,
                            link,
                            categories,
                            content,
                            isLoading
                        } = post

                        return (
                            <AcademyPost 
                                key={index}
                                title={title}
                                link={link}
                                excerpt={content}
                                categories={categories}
                                largeheading
                                isLoading={isLoading}
                            />
                        )
                    })
                    }
                </Slider>
            </div>
        </div>
    )
}
