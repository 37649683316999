import React from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'
import Button from 'components/button'

export default function Delete({ type, data }) {
    const { title, copy, callback } = data

    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: '',
        })
    }

    return (
        <div className={type} onClick={(e) => e.stopPropagation()}>
            <X className='modal-close' onClick={closeModal} />
            <div>
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
                <div className="button-row">
                    <Button
                        label={'Okay'}
                        inverted
                        onClick={() => {
                            callback()
                            closeModal()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
