import React, { useState, useEffect, useContext } from 'react'
import { MainContext } from 'utils/context'

import { NavLink, useHistory } from 'react-router-dom'
import Select from 'react-select'

const Tabs = ({ 
	tabs,
	routeSlug
}) => {
	const [selectValue, setSelectValue] = useState(tabs[0].value)
	let history = useHistory()

	useEffect(() => {
		if (isMobile) {
			history.push(routeSlug+'/'+selectValue)
		}
	}, [selectValue])

	const { isMobile } = useContext(MainContext)

	const reactSelectValues = tabs.map(tab => {
		return {
			label: tab.label,
			value: tab.value
		}
	})

	return (
		<div className='tabs'>
			{!isMobile ? (
				<ul>
					{tabs.map(({ value, label, hidden, availableFeatures }, index) => {
						if (hidden) {
							return null
						}

						return (
							<li key={index}>
								<NavLink
									exact
									to={routeSlug+'/'+value}
								>
									{label}
									{ availableFeatures &&
										<span dangerouslySetInnerHTML={{ __html: availableFeatures }} />
									}
								</NavLink>
							</li>
						)
					})}
				</ul>
			) : (
				<section>
					<Select
						className='react-select-container'
						classNamePrefix='react-select'
						options={reactSelectValues}
						placeholder={tabs[0].label}
						onChange={event => {
							setSelectValue(event.value)
						}}
					/>
				</section>
			)}
		</div>
	)
}

export default Tabs