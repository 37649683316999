import React, { useEffect, useState } from 'react'
import {
    Redirect,
    Route,
} from "react-router-dom";
import { spring, AnimatedSwitch } from 'react-router-transition'

import AuthHeader from './authHeader'
import Login from './login'
import Register from './register'
import ForgotPassword from './forgotPassword'
import ForgotPasswordConfirm from './forgotPasswordConfirm'

import { useDispatch } from 'react-redux'
import { getUserByToken } from 'state/actions'
import { useHistory } from 'react-router'

import background from 'assets/images/login-bg.jpg'
import logo from 'assets/images/myatomos-logo.png'

import axios from 'axios'

const Auth = ({
    match
}) => {
    const [hasMounted, setHasMounted] = useState(false)
    const { path: matchPath } = match
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            setHasMounted(true)
        }, 300)

        const token = window.localStorage.getItem('token')

        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

            dispatch(getUserByToken(({ type }) => {
                if (type === 'FAILED') {
                    window.localStorage.removeItem('token')
                }else{
                    history.push('/')
                }
            }))
        }
    }, [])


    useEffect(() => {

    }, [])

    const mapStyles = styles => {
        return {
            opacity: styles.opacity,
            transform: `translateY(${styles.translateY}px)`,
        }
    }

    const bounce = val => {
        return spring(val, {
            stiffness: 330,
            damping: 55,
        })
    }

    const bounceTransition = {
        atEnter: {
            opacity: 0,
            translateY: -12,
        },
        atLeave: {
            opacity: bounce(0),
            translateY: bounce(12),
        },
        atActive: {
            opacity: bounce(1),
            translateY: bounce(0),
        },
    }

    const routes = [
        {
            path: '/login',
            Component: Login
        }, {
            path: '/register',
            Component: Register
        }, {
            path: '/forgot-password',
            Component: ForgotPassword
        },{
            path: `/forgot-password-confirm`,
            Component: ForgotPasswordConfirm
        }, {
            path: '/login',
            isRedirect: true
        }
    ]

    return (
        <div className={`auth-main ${hasMounted ? 'mounted' : ''}`}>
            <div className="auth-inner" style={{ backgroundImage: `url(${background})` }}>
                <div className="auth-inner-mobile" style={{ backgroundImage: `url(${background})` }} />
                <AuthHeader />
                <div className={'vertical-20'}>
                    <div className='logo-main'>
                        <img src={logo} alt="Atomos" />
                    </div>
                    <div className={'inner'}>
                        <AnimatedSwitch
                            atEnter={bounceTransition.atEnter}
                            atLeave={bounceTransition.atLeave}
                            atActive={bounceTransition.atActive}
                            mapStyles={mapStyles}
                            className="switch-wrapper"
                        >
                            {routes.map(route => {
                                const {
                                    path,
                                    Component,
                                    isRedirect
                                } = route

                                const _path = `${matchPath}${path}`

                                if (isRedirect) {
                                    return (
                                        <Redirect key={path} to={_path} />
                                    )
                                }

                                return (
                                    <Route key={path} path={_path} render={params => (
                                        <Component 
                                            {...params} 
                                            isAuth
                                        />
                                    )} />
                                )
                            })}
                        </AnimatedSwitch>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth