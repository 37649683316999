import React from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'
import DeleteUser from 'components/auth/deleteUser'

export default function Delete({ type, data }) {
	const { title, copy, user } = data

	const dispatch = useDispatch()

	const closeModal = () => {
		dispatch({
			type: 'OPEN_MODAL',
			payload: '',
		})
	}

	return (
		<div className={type} onClick={(e) => e.stopPropagation()}>
			<X className='modal-close' onClick={closeModal} />
			<div>
                <DeleteUser
					isEdit={true}
					title={title ? title : ''}
					copy={copy ? copy : ''}
					user={user}
				/>
			</div>
		</div>
	)
}
