import React, { useEffect, useState } from 'react'
import { Menu } from 'react-feather'
import { Link } from 'react-router-dom'

import UserButton from 'components/userButton'

import { useMainContext, ToggleMenu } from 'utils'
import { useSelector } from 'react-redux'
import { getMenuToggle } from 'state/selectors'

import logo from 'assets/images/myatomos-logo.png'
import inverseLogo from 'assets/images/myatomos-logo-black.png'

const Header = ({ user }) => {
	const { isHeaderTransparent } = useMainContext()
	const doToggleMenu = ToggleMenu()
	const menuToggle = useSelector(getMenuToggle)

	const isMenuActive = menuToggle.data ? menuToggle.data.active : false

	const _window = typeof window !== 'undefined' ? window : null

	const [hasScrolled, setHasScrolled] = useState(false)

	useEffect(() => {
		let scrollHandler = null

		if (_window) {
			scrollHandler = _window.addEventListener(
				'scroll',
				checkInvertedHeaderOffsets
			)
		}

		return () => {
			if (scrollHandler) {
				scrollHandler.remove()
			}
		}
	}, [hasScrolled])

	const checkInvertedHeaderOffsets = () => {
		if (_window) {
			const scrollOffset = _window.pageYOffset

			if (scrollOffset > 10) {
				setHasScrolled(true)
			} else if (scrollOffset <= 10) {
				setHasScrolled(false)
			}
		}
	}

	useEffect(() => {
		doToggleMenu({
			data: {
				active: false
			}
		})
	}, [])

	const handleToggleMenu = () => {
		doToggleMenu({
			data: {
				active: isMenuActive ? false : true
			}
		})
	}

	let className = 'main-header '

	if (isHeaderTransparent) {
		className += 'transparent '
	}
	if (hasScrolled) {
		className += 'has-scrolled '
	}
	if (isHeaderTransparent && hasScrolled) {
		className += 'transparent-scrolled'
	}

	return (
		<header className={className}>
			<UserButton user={user} />
			<Link exact='true' to='/' className="main-header-logo">
				<img src={isHeaderTransparent ? logo : inverseLogo} />
			</Link>
			<div
				className={`menuToggle ${
					!isHeaderTransparent ? 'black-logo' : ''
				}`}
				onClick={handleToggleMenu}
			>
				<Menu size={36} />
			</div>
			<div
				className={isMenuActive ? 'hideMenu active' : 'hideMenu'}
				onClick={handleToggleMenu}
			/>
		</header>
	)
}

export default Header