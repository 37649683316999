import GA4React from 'ga-4-react'

let ga4react

export async function init(G, history) {
    if (!GA4React.isInitialized() && G && process.browser) {
        ga4react = new GA4React(G, { debug_mode: false })

        try {
            await ga4react.initialize()

            logPageViews(history)
        } catch (error) {
            console.error(error)
        }
    }
}

function logPageView() {
    ga4react.pageview(window.location.pathname)
}

function logPageViews(history) {
    logPageView()

    history.listen(() => {
        logPageView()
    })
}

export function logEvent(action, label, category) {
    ga4react.event(action, label, category)
}
