import { normalize } from 'normalizr'
import * as schemas from 'state/schemas'
import merge from 'lodash/merge'

const initialState = {
	currentUserId: null,
	currentRegistrationId: null,
	currentModal: null,
	entities: {}
}

export default function reducer (state = initialState, action) {
	let normalizrResponse = {}
	let entities = {}

	switch (action.type) {
		case 'LOG_IN':
		case 'GET_USER_DATA':
		case 'POST_REGISTRATION':
			normalizrResponse = normalize(action.payload, schemas.userSchema)

			entities = normalizrResponse.entities

			return merge(
				{},
				{
					...state,
					currentUserId: normalizrResponse.result
				},
				{ entities }
			)
		case 'GET_ACADEMY_POSTS':
			normalizrResponse = normalize(action.payload, [schemas.postsSchema])

			entities = normalizrResponse.entities

			return merge(
				{},
				{
					...state
				},
				{ entities }
			)
		case 'GET_ACADEMY_POSTS_BY_CATEGORY':
			normalizrResponse = normalize(action.payload, [
				schemas.categoriesSchema
			])

			entities = normalizrResponse.entities

			return merge(
				{},
				{
					...state,
					academyCategoryIds: normalizrResponse.result
				},
				{ entities }
			)
		case 'GET_REGISTRATION':
			normalizrResponse = normalize(
				action.payload,
				schemas.registrationsSchema
			)
			entities = normalizrResponse.entities

			return merge({}, {
				...state,
				currentRegistrationId: normalizrResponse.result
			}, { entities })
		case 'POST_EXTENDED_WARRANTY':
		case 'DO_PEARSON_ACTIVATION':
		case 'ACTIVATE_FREE_FEATURE':
		case 'DEACTIVATE_FEATURE':
			normalizrResponse = normalize(action.payload, schemas.registrationsSchema)
			entities = normalizrResponse.entities

			return merge({}, {
				...state
			}, { entities })
		case 'GET_FIRMWARE_UPDATES':
			const firmware = action.payload

			return merge({}, {
				...state
			}, { firmware })
		case 'GET_CAMERA_BRANDS':
			const cameraBrands = action.payload

			return merge({}, {
				...state
			}, { cameraBrands })
		case 'GET_DASHBOARD_CTAS':
			const ctas = action.payload

			return merge({}, {
				...state
			}, { ctas })
		case 'GET_DASHBOARD_HERO_POSTS':
			const heroPosts = action.payload

			return merge({}, {
				...state
			}, { heroPosts })
		case 'GET_RESOURCES':
			const resources = action.payload

			return merge({}, {
				...state
			}, { resources })
		case 'OPEN_MODAL':
			return merge({}, {
				...state
			}, { currentModal: action.payload })
		case 'TOGGLE_MENU':
			return merge({}, {
				...state
			}, { toggleMenu: action.payload })
		case 'GET_REGISTER_PRODUCTS':
			normalizrResponse = normalize(action.payload, [
				schemas.productsSchema
			])

			entities = normalizrResponse.entities

			return merge({}, {
				...state
			}, { entities })
		case 'GET_PRODUCT_FEATURES':
			normalizrResponse = normalize(action.payload, [
				schemas.featureSchema
			])

			entities = normalizrResponse.entities

			return merge({}, {
				...state
			}, { entities })
		case 'GET_USER_DATA_BY_ID':
			normalizrResponse = normalize(action.payload, schemas.userSchema)

			entities = normalizrResponse.entities

			return merge(
				{},
				{
					...state,
				},
				{ entities }
			)
		case 'NORMALIZE_USERS':
			normalizrResponse = normalize(action.payload, [
				schemas.userSchema
			])

			entities = normalizrResponse.entities

			return merge({}, {
				...state
			}, { entities })
		case 'NORMALIZE_PRODUCTS':
			normalizrResponse = normalize(action.payload, [
				schemas.registrationsSchema
			])

			entities = normalizrResponse.entities

			return merge({}, {
				...state
			}, { entities })
		default:
			return state
	}
}
