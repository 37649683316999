import React, { useState, useRef } from 'react'
import { X } from 'react-feather'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { denormalize } from 'normalizr'

import { useSelector } from 'react-redux'
import { userSchema } from 'state/schemas'

import {
    searchCustomers,
    reassignRegistration
} from 'state/actions'

import Button from 'components/button'
import loadingImage from 'assets/images/loading.svg'

export default function Reassign({ type, data }) {
    const {
        title,
        copy,
        registration
    } = data

    const [searchValue, setSearchValue] = useState('')
    const [resultIds, setResultIds] = useState([])
    const [isLoading, setLoading] = useState(false)

    const [selectedUserId, setSelectedUserId] = useState(null)

    const results = useSelector(state => {
        return denormalize(resultIds, [userSchema], state.main.entities)
    })

    results.sort((a, b) => {
        const aName = a.email.toLowerCase()
        const bName = b.email.toLowerCase()

        if (aName < bName) { return -1 }
        if (aName > bName) { return 1 }
        return 0
    })

    const handleChange = value => {
        setSearchValue(value)

        handleSearchRef.current(value)
    }

    const handleSearch = term => {
        dispatch(searchCustomers({
            email: term
        }, callback => {
            setResultIds(callback.data)
        }))
    }

    const handleSearchRef = useRef(debounce(handleSearch, 300))

    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL',
            payload: ''
        })
    }

    const handleReassign = () => {
        setLoading(true)

        dispatch(reassignRegistration(registration.id, selectedUserId, response => {
            setLoading(false)

            closeModal()

            setTimeout(() => {
                dispatch({
                    type: 'OPEN_MODAL',
                    payload: {
                        type: 'TEXT',
                        data: {
                            title: 'Registration reassigned',
                            copy: `We've reassigned this registered device now.`
                        }
                    }
                })
            },300)
        }))
    }

    return (
        <div
            className={`${type}`}
            onClick={e => e.stopPropagation()}
        >
            <X className='modal-close' onClick={closeModal} />
            <div>
                {title && (
                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                )}
                <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
                <div className={`form-container ${isLoading ? 'loading' : ''}`}>
                    <form onSubmit={e => e.preventDefault()}>
                        <img src={loadingImage} className={`loading`} />
                        <div className={'loader-block'}>
                            <div className={'form-input'}>
                                <i className={'fa fa-search'} />
                                <input
                                    type="text"
                                    placeholder={'Search email address'}
                                    value={searchValue}
                                    onChange={e => handleChange(e.target.value)}
                                />
                            </div>
                            <ul className="results">
                                {results.map(result => {
                                    if (!result.first_name || !result.last_name || !result.email) {
                                        return null
                                    }

                                    const isSelected = selectedUserId === result.id

                                    return (
                                        <li key={result.id}>
                                            <label className={isSelected ? 'selected' : ''}>
                                                <input
                                                    type={'radio'}
                                                    checked={isSelected}
                                                    onChange={() => setSelectedUserId(result.id)}
                                                />
                                                {result.email} ({result.first_name} {result.last_name})
                                    </label>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className="button-row">
                                <Button
                                    label={'Reassign registration'}
                                    inverted
                                    onClick={handleReassign}
                                    disabled={!selectedUserId}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}
