import React, { useEffect } from 'react'
import {
    useLocation,
    useHistory
} from 'react-router'

const ProcessQueryParam = () => {
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const {
            search
        } = location

        if (search) {
            const params = search.replace('?', '').split('&')

            const paramsObject = {}

            params.forEach(param => {
                const parts = param.split('=')

                paramsObject[parts[0]] = parts[1]
            })

            if (paramsObject.type && paramsObject.type === 'zato-connect' && paramsObject.aid) {
                const serial_number = paramsObject.aid

                delete paramsObject.aid

                paramsObject.serial_number = serial_number
            }

            window.urlParams = paramsObject
        }

        history.push('/')
    }, [])

    return (
        <div>

        </div>
    )
}

export default ProcessQueryParam