import React, { useState, useContext } from 'react'
import { FilePlus } from 'react-feather'
import { postExtendedWarranty } from 'state/actions'
import { useDispatch } from 'react-redux'
import { MainContext } from 'utils/context'

import moment from 'moment'

const extendedWarrantyWhitelist = [
    151,
    152,
    148,
    145,
    153,
    154,
    155,
    157,
    141,
    142,
    113,
    70,
    112,
    149,
    170,
    175,
    166, // Atomos connect
    168, // Zato connect
    169,
    170,
    171,
    172,
    173,
    174,
    176,
    177,
    178,
    179,
    180,
    181,
    182,
    183,
    184,
    185
]

function SingleRegistrationWarranty({
    registration,
    canEdit,
    isSupport
}) {
    const { isMobile } = useContext(MainContext)

    const [fileUploading, setFileUploading] = useState(false)
    const [fileUpdated, setFileUpdated] = useState(false)
    const [fileUpdaterHover, setFileUpdaterHover] = useState(false)

    const dispatch = useDispatch()

    const shouldShow = (() => {
        if (!extendedWarrantyWhitelist.includes(registration.product_id)) {
            return false
        }

        if (isSupport) {
            return true
        }

        if (!registration.date_of_purchase || registration.date_of_purchase === '0000-00-00') {
            return false
        }

        const threeYearsAgo = moment().subtract(3, 'years')
        const dateOfPurchase = moment(registration.date_of_purchase)

        // Always show if they've already got it active
        if (registration.upload) {
            return true
        }

        if (dateOfPurchase.isBefore(threeYearsAgo)) {
            return false
        }

        return true
    })()

    if (!shouldShow) {
        return (
            <section className="singleRegistration-warranty">
                <div className={'title-inner'}>
                    <h3>
                        Extended warranty
                    </h3>
                    <div
                        className={'copy'}
                    >
                        Unfortunately, you are not eligible for extended warranty on your {registration.product.product_name}. Please contact support if you require assistance.
                    </div>
                </div>
            </section>
        )
    }

    const activeFile = registration ? registration.upload : null

    const handleOnChange = value => {
        setFileUpdaterHover(false)
        setFileUploading(true)

        const proof_of_purchase = value.target.files[0]
        const registrationId = registration.id

        dispatch(postExtendedWarranty({ proof_of_purchase, registrationId }, callback => {
            const { type } = callback

            setFileUploading(false)
            setFileUpdated(true)

            setTimeout(() => {
                setFileUpdated(false)
            }, 5000)

        }))
    }

    const _setFileUpdater = value => {
        if (value.type === 'dragover') {
            setFileUpdaterHover(true)
        } else if (value.type === 'dragleave') {
            setFileUpdaterHover(false)
        }
    }

    return (
        <section className="singleRegistration-warranty">
            <div className={'title-inner'}>
                <h3>
                    Extended warranty
                </h3>
                <div
                    className={'copy'}
                >
                    Activate 3 years of extended warranty for your device below.
                </div>
            </div>
            {activeFile &&
                <div className="singleRegistration-warranty-activated">
                    You've already uploaded your proof-of-purchase for this registered device <a href={activeFile.url} target="_blank" rel="noreferrer">here</a>.
                    {canEdit && <div>Should you wish to replace it, upload below.</div>}
                </div>
            }
            {fileUpdated &&
                <div className="singleRegistration-warranty-update">
                    Proof-of-purchase updated.
                </div>
            }
            {canEdit &&
                <form>
                    <div className="warranty-files-cont">
                        <input
                            type="file"
                            name="warranty-files"
                            id="warranty-files"
                            className="warranty-files"
                            onChange={handleOnChange}
                            onDragOver={_setFileUpdater}
                            onDragLeave={_setFileUpdater}
                        />
                        <label htmlFor="warranty-files" className={fileUpdaterHover ? "warranty-files-label-cont dragOver" : "warranty-files-label-cont"}>
                            <div className="warranty-files-label">
                                {fileUploading ?
                                    <div>Uploading file...</div>
                                    :
                                    <>
                                        <FilePlus size={80} strokeWidth={0.7} />
                                        <div>
                                            {isMobile ? 'Click here to' : 'Drag and drop or'} <em>browse</em> files here.
                                            <span dangerouslySetInnerHTML={{ __html: 'Files accepted: jpg, png, pdf, doc, docx.' }} />
                                        </div>
                                    </>
                                }
                            </div>
                        </label>
                    </div>
                </form>
            }
        </section>
    )
}


const Warranty = props => {
    return (
        <>
            <SingleRegistrationWarranty
                {...props}
            />
        </>
    )
}

export default Warranty